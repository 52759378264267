import { ValidateCode } from "#baseUrl/models/dto/outbound/validateCode.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Observable } from "rxjs";
import { AppState } from "../../state/app-state";
import { ConfigurationService } from "../configuration.service";
import { AAService } from "./aa.service";

@Injectable()
export class DeviceCodeService extends AAService {
	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		captcha: ReCaptchaV3Service
	) {
		super(httpService, configurationService, store, captcha);
	}

	validateDeviceCode(code: string): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ValidateDeviceCode}`;
		const body: ValidateCode = {
			Value: code,
		};
		return this.doPost(url, body);
	}

	selectUser(newUser: boolean): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SelectUser}`.replace("{{newUser}}", newUser.toString());
		return this.doPost(url, {});
	}
}
