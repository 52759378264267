import { Injectable } from "@angular/core";
import { NavigationStart } from "@angular/router";
import { SessionStorageService } from "@nosinovacao/nosid-mfe-common";
import { ResponseAction } from "../../models/dto/response-action.model";
import { ConfigurationService } from "../configuration.service";

interface HistoryState {
	url: string;
	data: ResponseAction<any>;
}

@Injectable()
export class NavigationStateService {
	private historyPointer: number;
	private historyStack: HistoryState[];
	private storageToken: string;

	constructor(private readonly configurationService: ConfigurationService, private readonly sessionStorage: SessionStorageService) {
		this.historyPointer = -1;
		this.historyStack = [];
		this.loadSessionStorage();
	}

	private get SessionStorageToken(): string {
		if (this.storageToken) {
			return this.storageToken;
		}
		this.storageToken = `${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.NavigationHistory}`;

		return this.storageToken;
	}

	reset(): void {
		this.resetSessionStorage();
		this.historyPointer = -1;
		this.historyStack = [];
	}

	isInitState(): boolean {
		return this.historyPointer < 0;
	}

	isFirstState(): boolean {
		return this.historyPointer === 0;
	}

	saveNavigation(event: NavigationStart, data: ResponseAction<any>): void {
		if (!data.Token) {
			data = {
				...data,
				Token: this.getCurrentState()?.Token,
			} as ResponseAction<any>;
		}
		this.historyStack.length = this.historyPointer + 1;

		this.historyStack.push({
			url: event.url,
			data,
		} as HistoryState);

		this.historyPointer++;

		this.saveSessionStorage();
	}

	getCurrentUrl(): string {
		return this.historyStack[this.historyPointer].url;
	}

	getCurrentState(): ResponseAction<any> {
		return this.historyStack[this.historyPointer] ? this.historyStack[this.historyPointer].data : null;
	}

	updateCurrentStateToken(token: string): void {
		if (!this.isInitState()) {
			this.historyStack[this.historyPointer].data.Token = token;
			this.saveSessionStorage();
		}
	}

	popNavigation(event: NavigationStart): void {
		const prevIndex = Math.max(0, this.historyPointer - 1);
		const nextIndex = Math.min(this.historyPointer + 1, this.historyStack.length - 1);

		const isBack = this.isBackNavigation(event);
		const isForward = this.isForwardNavigation(event);

		if (isBack) {
			this.historyPointer = prevIndex;
		}

		if (isForward) {
			this.historyPointer = nextIndex;
		}

		this.saveSessionStorage();
	}

	isBackNavigation(event: NavigationStart): boolean {
		const prevIndex = Math.max(0, this.historyPointer - 1);
		return this.historyStack[prevIndex].url === event.url;
	}

	isForwardNavigation(event: NavigationStart): boolean {
		const nextIndex = Math.min(this.historyPointer + 1, this.historyStack.length - 1);
		return this.historyStack[nextIndex].url === event.url;
	}

	private resetSessionStorage(): void {
		this.sessionStorage.remove(this.SessionStorageToken);
	}

	private loadSessionStorage(): void {
		if (this.sessionStorage.checkItem(this.SessionStorageToken)) {
			const lastHistory = this.sessionStorage.getObject(this.SessionStorageToken);
			this.historyPointer = lastHistory.hp;
			this.historyStack = lastHistory.hs;
		}
	}

	private saveSessionStorage(): void {
		this.sessionStorage.setObject(this.SessionStorageToken, {
			hp: this.historyPointer,
			hs: this.historyStack,
		});
	}
}
