import { Injectable } from "@angular/core";
import { ConfigurationService } from "#baseUrl/services/configuration.service";
import { SessionStorageService } from "@nosinovacao/nosid-mfe-common";

@Injectable()
export class EnterpriseContextService {
	constructor(private readonly storage: SessionStorageService, private readonly config: ConfigurationService) { }

	get enterpriseContext() {
		return this.storage.getString(this.key) ?? "";
	}

	set enterpriseContext(context: string) {
		this.storage.setString(this.key, context);
	}

	private get key(): string {
		return this.config.LocalStorage.BaseToken + this.config.LocalStorage.EnterpriseContext;
	}
}
