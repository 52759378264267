import { EnvironmentConfigurationEnum } from "#baseUrl/models/configurations/environment/environmentConfiguration.enum";
import { FirebaseConfiguration, FirebaseNativeLogginEnum } from "#baseUrl/models/configurations/logging/firebase-configuration.interface";
import "firebase/analytics";
import firebase from "firebase/app";
import { AndroidAnalytics } from "./android-analytics.service";
import { IosAnalytics } from "./ios-analytics.service";
import { LoggerProvider } from "./logger.interface";
import { NativeAnalytics } from "./native-analytics";
import { ConfigurationService } from "../configuration.service";
import { TemplatesConfigurationService } from "../templatesConfiguration.service";

type AnalyticsClient = firebase.analytics.Analytics | NativeAnalytics;

export class FirebaseProvider extends LoggerProvider {
	private readonly analyticsClient: Promise<AnalyticsClient[]>;

	constructor(configurationService: ConfigurationService, templatesConfiguration: TemplatesConfigurationService) {
		super(configurationService, templatesConfiguration);

		let clientPromise = null;
		Object.defineProperty(this, "analyticsClient", {
			get: () => {
				if (!clientPromise) {
					clientPromise = this.load();
				}

				return clientPromise;
			},
		});
	}

	logEvent(message: string, data?: { [key: string]: any }): void {
		this.analyticsClient.then((clients) => {
			clients.forEach((c) => {
				!!data ? c.logEvent(message, data) : c.logEvent(message);
			});
		});
	}

	private load(): Promise<AnalyticsClient[]> {
		//	MULTIPLE GA4
		//	https://firebase.google.com/docs/projects/multiprojects#web
		//	Native GA
		//	https://firebase.google.com/docs/analytics/webview?platform=android

		const configs: [FirebaseConfiguration | FirebaseNativeLogginEnum] = [this.configurationService.LoggingConfiguration.Firebase];

		const specificFirebaseConfig = this.templatesConfiguration.ClientTemplate.Firebase;

		if (specificFirebaseConfig) {
			configs.push(specificFirebaseConfig);
		}

		const analytics = configs.map((c) => (typeof c === "string" ? this.initNativeAnalytics(c) : this.initWebAnalytics(c))).filter((a) => !!a);

		return Promise.resolve(analytics);
	}

	private initWebAnalytics(config: FirebaseConfiguration): firebase.analytics.Analytics {
		const app = firebase.initializeApp(
			{
				apiKey: config.ApiKey,
				authDomain: config.AuthDomain,
				projectId: config.ProjectId,
				storageBucket: config.StorageBucket,
				messagingSenderId: config.MessagingSenderId,
				appId: config.AppId,
				measurementId: config.MeasurementId,
			},
			config.AppName
		);

		const analyticsClient = app.analytics();

		analyticsClient.setUserProperties({ ClientId: this.configurationService.ClientId }, { global: true });

		return analyticsClient;
	}

	private initNativeAnalytics(config: FirebaseNativeLogginEnum): NativeAnalytics {
		let analyticsClient: NativeAnalytics = null;
		try {
			switch (config) {
				case FirebaseNativeLogginEnum.Android:
					analyticsClient = new AndroidAnalytics();
					break;
				case FirebaseNativeLogginEnum.IOS:
					analyticsClient = new IosAnalytics();
					break;
			}
		} catch (e) {
			console.error(e);
		}

		analyticsClient?.setUserProperties({ ClientId: this.configurationService.ClientId });

		return analyticsClient;
	}
}
