import { ImageProfile } from "#baseUrl/models/dto/outbound/image-profile.model";
import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class MageService {
	constructor(private readonly configurationService: ConfigurationService) {}

	getImage(sourceUri: string, profile: ImageProfile): string {
		if (!sourceUri) {
			return null;
		}
		const baseMageUrl = `${this.configurationService.APIConfiguration.Mage.BaseUrl}${this.configurationService.APIConfiguration.Mage.GetImages}`;
		const params = new HttpParams().set("sourceUri", sourceUri).set("profile", profile).set("client_id", this.configurationService.DefaultClientId);
		return `${baseMageUrl}?${params.toString()}`;
	}
}
