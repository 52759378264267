import { createFeatureSelector, createSelector } from "@ngrx/store";

export enum SnackBarType {
	Info,
	Warn,
}

export interface SnackBarState {
	Visible?: boolean;
	Type: SnackBarType;
	Message?: string;
	Translatable?: {
		Key: string;
		Params: { [key: string]: any };
	};
}

export const snackBarFeatureSelector = createFeatureSelector("SnackBar");
export const selectSnackBar = createSelector(snackBarFeatureSelector, (state: SnackBarState) => state);
