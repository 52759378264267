export enum Screen {
	LandingPage = "LandingPage",
	Complete = "Complete",
	SendSms = "SendSms",
	ChangePhone = "ChangePhone",
	CodeInput = "CodeInput",
	EmailValidated = "EmailValidated",
	DigitalBirthLandingPage = "DigitalBirthLandingPage",
	DigitalBirthComplete = "DigitalBirthComplete",
	Username = "Username",
	SinglePageLogin = "SinglePageLogin",
	Password = "Password",
	PasswordStatusPending = "PasswordStatusPending",
	UserBlocked = "UserBlocked",
	CountryCodeConfirmation = "CountryCodeConfirmation",
	NewPassword = "NewPassword",
	Confirm = "Confirm",
	HolderInput = "HolderInput",
	ContactSelection = "ContactSelection",
	ChangeContact = "ChangeContact",
	SelectAccount = "SelectAccount",
	Terms = "Terms",
	Consents = "Consents",
	PrivacyPolicies = "PrivacyPolicies",
	Authorize = "Authorize",
	ScreenError = "Error",
	UnauthorizedUser = "UnauthorizedUser",
	UnauthorizedApp = "UnauthorizedApp",
	PermissionRequested = "PermissionRequested",
	GenericCountryCodeConfirmation = "GenericCountryCodeConfirmation",
	SocialLandingPage = "SocialLandingPage",
	SocialComplete = "SocialComplete",
	Banner = "Banner",
	Configuration = "Configuration",
	SelectProfile = "SelectProfile",
	CreateProfile = "CreateProfile",
	SelectUser = "SelectUser",
	Forbidden = "Forbidden",
	MandatoryPasswordRecovery = "MandatoryPasswordRecovery",
	Promote = "Promote",
	RollbackComplete = "RollbackComplete",
}
