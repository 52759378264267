import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { SignupChangePhone } from "#baseUrl/models/dto/outbound/signup-change-phone.model";
import { SignupConfirmcode } from "#baseUrl/models/dto/outbound/signup-code-confirm.model";
import { SignUp } from "../../models/dto/outbound/signup.model";
import { AppState } from "../../state/app-state";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { mergeMap } from "rxjs/operators";

@Injectable()
export class SignUpService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	InitSignUp() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.InitSignUp}`;
		return this.doGet(url);
	}

	InitDigitalBirth(jwt: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.InitSignUp}?jwt=${jwt}`;
		return this.doGet(url);
	}

	SignUp(DisplayName: string, Phone: string, Password: string, Email: string, honeyPot?: string, tokenChallenge?: string) {
		const body: SignUp = {
			DisplayName,
			Phone,
			Password,
			Email,
			Username: honeyPot ? honeyPot : undefined,
		};
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignUp}`;

		if (tokenChallenge) {
			return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: tokenChallenge, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}
		return this.recaptchaService.execute("signup").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	SendSms(ChallengeToken?: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignupSendSms}`;

		if (ChallengeToken) {
			return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: ChallengeToken, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}

		return this.recaptchaService.execute("signupSendSms").pipe(
			mergeMap((token) => {
				return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	NotMyContact(ChallengeToken?: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignupChangePhone}`;
		if (ChallengeToken) {
			return this.doGet(url, { [this.CAPTCHA_HTTP_HEADER]: ChallengeToken, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}
		return this.recaptchaService.execute("signupNotMyContact").pipe(
			mergeMap((token) => {
				return this.doGet(url, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	UpdatePhone(phoneNumber: string, ChallengeToken?: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignupUpdatePhoneNumber}`;

		const body: SignupChangePhone = {
			Value: phoneNumber,
		};

		if (ChallengeToken) {
			return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: ChallengeToken, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}

		return this.recaptchaService.execute("signupChangePhone").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	ConfirmCode(code: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignupCodeValidate}`;

		const body: SignupConfirmcode = {
			Value: code,
		};

		return this.doPost(url, body);
	}

	ValidateEmail(jwt: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SignupEmailValidation}`;

		return this.doPost(url, jwt);
	}
}
