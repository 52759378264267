import { createAction, props } from "@ngrx/store";
import { PopupState } from "./popup-state";

export const SHOW_POPUP = "[POPUP] Show popup";

export const HIDE_POPUP = "[POPUP] Hide popup";

export const showPopup = createAction(SHOW_POPUP, props<PopupState>());

export const hidePopup = createAction(HIDE_POPUP);
