export enum Action {
	InvalidRequest = "InvalidRequest",
	Unauthenticated = "Unauthenticated",
	UnauthenticatedSinglePageLogin = "UnauthenticatedSinglePageLogin",
	PasswordInputLoginHint = "PasswordInputLoginHint",
	SocialNetworkAssociation = "SocialNetworkAssociation",
	PasswordInput = "PasswordInput",
	UserBlocked = "UserBlocked",
	SignUp = "SignUp",
	SignUpDigitalBirth = "SignUpDigitalBirth",
	SignUpDigitalBirthInit = "SignUpDigitalBirthInit",
	SignUpByInvitation = "SignUpByInvitation",
	SignUpByInvitationInit = "SignUpByInvitationInit",
	SignUpSendSms = "SignUpSendSms",
	SignUpChangePhone = "SignUpChangePhone",
	SignUpPhoneCodeValidation = "SignUpPhoneCodeValidation",
	SignUpEmailValidated = "SignUpEmailValidated",
	SignUpEmailValidation = "SignUpEmailValidation",
	SignUpComplete = "SignUpComplete",
	SignUpByInvitationComplete = "SignUpByInvitationComplete",
	SignUpDigitalBirthComplete = "SignUpDigitalBirthComplete",
	SignUpSocialNetwork = "SignUpSocialNetwork",
	SignUpSocialNetworkComplete = "SignUpSocialNetworkComplete",
	MandatoryEmailCodeValidation = "MandatoryEmailCodeValidation",
	MandatoryChangeEmail = "MandatoryChangeEmail",
	MandatoryEmailValidated = "MandatoryEmailValidated",
	AssociationsShowDetailsPrimary = "AssociationsShowDetailsPrimary",
	AssociationsShowDetailsSecondary = "AssociationsShowDetailsSecondary",
	AssociationsDetailsMandatoryInfo = "AssociationsDetailsMandatoryInfo",
	AssociationsIdentifyPrimary = "AssociationsIdentifyPrimary",
	AssociationsNifIdentification = "AssociationsNifIdentification",
	AssociationsNifIdentificationContactSelection = "AssociationsNifIdentificationContactSelection",
	AssociationsNifIdentificationSecondValidationEmailCodeValidation = "AssociationsNifIdentificationSecondValidationEmailCodeValidation",
	AssociationsNifIdentificationSecondValidationPhoneCodeValidation = "AssociationsNifIdentificationSecondValidationPhoneCodeValidation",
	AssociationsPassportIdentification = "AssociationsPassportIdentification",
	AssociationsPassportIdentificationContactSelection = "AssociationsPassportIdentificationContactSelection",
	AssociationsPassportIdentificationSecondValidationEmailCodeValidation = "AssociationsPassportIdentificationSecondValidationEmailCodeValidation",
	AssociationsPassportIdentificationSecondValidationPhoneCodeValidation = "AssociationsPassportIdentificationSecondValidationPhoneCodeValidation",
	AssociationsEmailIdentification = "AssociationsEmailIdentification",
	AssociationsEmailIdentificationCodeValidation = "AssociationsEmailIdentificationCodeValidation",
	AssociationsPhoneIdentification = "AssociationsPhoneIdentification",
	AssociationsPhoneIdentificationSendCode = "AssociationsPhoneIdentificationSendCode",
	AssociationsPhoneIdentificationCodeValidation = "AssociationsPhoneIdentificationCodeValidation",
	ApplicationNotAuthorized = "ApplicationNotAuthorized",
	ApplicationNotAuthorizedByAdmin = "ApplicationNotAuthorizedByAdmin",
	ApplicationPermissionRequested = "ApplicationPermissionRequested",
	UnauthorizedKid = "UnauthorizedKid",
	TermsAndConditions = "TermsAndConditions",
	Consents = "Consents",
	PrivacyPolicies = "PrivacyPolicies",
	AccountSelection = "AccountSelection",
	Protection = "Protection",
	ProtectionEmailCodeValidation = "ProtectionEmailCodeValidation",
	ProtectionChangeEmail = "ProtectionChangeEmail",
	ProtectionPhoneSendCode = "ProtectionPhoneSendCode",
	ProtectionPhoneCodeValidation = "ProtectionPhoneCodeValidation",
	ProtectionChangePhone = "ProtectionChangePhone",
	ProtectionComplete = "ProtectionComplete",
	PasswordRecovery = "PasswordRecovery",
	PasswordRecoveryPending = "PasswordRecoveryPending",
	PasswordRecoveryMandatory = "PasswordRecoveryMandatory",
	PasswordRecoveryPhoneSendSms = "PasswordRecoveryPhoneSendSms",
	PasswordRecoveryPhoneCodeValidation = "PasswordRecoveryPhoneCodeValidation",
	PasswordRecoveryEmailCodeValidation = "PasswordRecoveryEmailCodeValidation",
	PasswordRecoveryContactSelection = "PasswordRecoveryContactSelection",
	PasswordRecoveryNewPasswordDefinition = "PasswordRecoveryNewPasswordDefinition",
	PasswordRecoveryComplete = "PasswordRecoveryComplete",
	SocialNetworkAssociationUsernameInput = "SocialNetworkAssociationUsernameInput",
	SocialNetworkAssociationPasswordInput = "SocialNetworkAssociationPasswordInput",
	SocialNetworkAssociationComplete = "SocialNetworkAssociationComplete",
	//? 2FA
	TwoFactorAuthCodeValidation = "TwoFactorAuthCodeValidation",
	TwoFactorAuthCodeValidationEmail = "TwoFactorAuthCodeValidationEmail",
	//? 2FA Promotion
	TwoFactorAuthCodePromotion = "TwoFactorAuthCodePromotion",
	TwoFactorAuthCodeValidationPromotion = "TwoFactorAuthCodeValidationPromotion",
	TwoFactorAuthCodeValidationEmailPromotion = "TwoFactorAuthCodeValidationEmailPromotion",

	RollbackUserContacts = "RollbackUserContacts",

	RedirectTo = "RedirectTo",
	LoggedOut = "LoggedOut",
	KidsProfileSelection = "KidsProfileSelection",
	KidsProfileCreation = "KidsProfileCreation",
	DeviceCodesUserSelection = "DeviceCodesUserSelection",
	DeviceCodesComplete = "DeviceCodesComplete",

	GenericCountryCodeConfirmation = "GenericCountryCodeConfirmation",
	InvalidTokenError = "InvalidTokenError",
	ForbidenTokenError = "ForbidenTokenError",
	UnknownError = "UnknownError",
}
