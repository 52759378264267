import { createAction, props } from "@ngrx/store";
import { ResponseAction } from "../../models/dto/response-action.model";

export const UPDATE_TOKEN = "[CURRENT VIEW] Update token";

export const UPDATE_VIEW_DATA = "[CURRENT VIEW] Update current view data";

export const SET_VIEW_DATA_LOADING = "[CURRENT VIEW] Set current view data loading";

export const SET_VIEW_DATA_LOADED = "[CURRENT VIEW] Set current view data loaded";

export const updateToken = createAction(UPDATE_TOKEN, props<ResponseAction<any>>());

export const updateViewData = createAction(UPDATE_VIEW_DATA, props<ResponseAction<any>>());

export const setViewDataLoading = createAction(SET_VIEW_DATA_LOADING);

export const setViewDataLoaded = createAction(SET_VIEW_DATA_LOADED);
