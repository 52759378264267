import { createAction, props } from "@ngrx/store";
import { SnackBarState } from "./snackbar-state";

export const SHOW_SNACKBAR = "[SNACKBAR] Show snack-bar";

export const HIDE_SNACKBAR = "[SNACKBAR] Hide snack-bar";

export const showSnackBar = createAction(SHOW_SNACKBAR, props<SnackBarState>());

export const hideSnackBar = createAction(HIDE_SNACKBAR);
