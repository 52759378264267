import { ErrorCodes } from "#baseUrl/models/dto/inbound/error/errors.enum";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { Action } from "#baseUrl/models/navigation/action.enum";
import { FacadeService } from "#baseUrl/services/facade.service";
import { setViewDataLoaded } from "#baseUrl/state/current-view/current-view-actions";
import { navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { EMPTY, Observable, from, of } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import { NOSIDError } from "../../models/dto/inbound/error/nosid-error";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private readonly facadeService: FacadeService, private readonly store: Store) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.includes(this.facadeService.configurationService.APIConfiguration.AA.BaseUrl)) {
			return next.handle(req);
		}

		const path = new URL(req.url).pathname;

		switch (path) {
			case this.facadeService.configurationService.APIConfiguration.AA.ValidateDeviceCode:
			case this.facadeService.configurationService.APIConfiguration.AA.Authorize:
				req = this.preProcessUserSessionTokenRequest(req);
				break;
			case this.facadeService.configurationService.APIConfiguration.AA.SignupEmailValidation:
			case this.facadeService.configurationService.APIConfiguration.AA.AccountProtectionChangeContactRollback:
				req = this.preProcessEmailValidationRequest(req);
				break;
			case this.facadeService.configurationService.APIConfiguration.AA.PasswordRecoveryFromEmail:
				break;
			case this.facadeService.configurationService.APIConfiguration.AA.InitSignUp:
				req = this.preProcessInitSignUpRequest(req);
				break;
			default:
				//	ADD OPERATION TOKEN (JWT FOR AA FLOW CONTROL)
				req = req.clone({
					setHeaders: {
						Authorization: `Bearer ${this.facadeService.navigationStateService.getCurrentState().Token}`,
					},
				});

				break;
		}

		if (req.body) {
			req = req.clone({ body: this.trimValues(req.body) });
		}

		return from(this.facadeService.deviceIdService.deviceId).pipe(
			mergeMap((deviceId) => {
				req = req.clone({
					headers: req.headers
						.set("x-device-id", deviceId)
						.set("x-enterprise-name", this.facadeService.enterpriseContextService.enterpriseContext)
						.set("x-clear-text-password", "true"),
					withCredentials: true,
				});
				return this.buildRequestHandler(req, next);
			})
		);
	}

	private buildRequestHandler(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			tap(this.handleSuccess.bind(this)),
			catchError((err) => this.handleError(err, req, next))
		);
	}

	private preProcessUserSessionTokenRequest(req: HttpRequest<any>): HttpRequest<any> {
		//	ADD SESSION TOKEN FROM LOCAL STORAGE("KEEP SESSION")
		const sessionTokenKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.SessionToken}`;
		const sessionToken =
			this.facadeService.localStorageService.getString(sessionTokenKey) || this.facadeService.sessionStorageService.getString(sessionTokenKey);
		if (!!sessionToken) {
			return req.clone({
				setHeaders: {
					Authorization: `Bearer ${sessionToken}`,
				},
			});
		}

		return req;
	}

	private preProcessEmailValidationRequest(req: HttpRequest<any>): HttpRequest<any> {
		//	ADD AUTHORIZATION TOKEN FROM EMAIL
		return req.clone({
			setHeaders: {
				Authorization: `Bearer ${req.body}`,
			},
			body: {},
		});
	}

	private preProcessInitSignUpRequest(req: HttpRequest<any>): HttpRequest<any> {
		const queries = new URL(req.url).searchParams;

		//DIGITAL BIRTH REQUEST WILL BE A POST AND WE NEED TO CONVERT IT TO A GET REQUEST WITH AUTHORIZATION HEADER
		if (queries.has("jwt")) {
			return req.clone({
				url: `${this.facadeService.configurationService.APIConfiguration.AA.BaseUrl}${this.facadeService.configurationService.APIConfiguration.AA.InitSignUp}`,
				setHeaders: {
					Authorization: `Bearer ${queries.get("jwt")}`,
				},
			});
		} else {
			//	NORMAL REGISTER FLOW ADD OPERATION TOKEN (JWT FOR AA FLOW CONTROL)
			return req.clone({
				setHeaders: {
					Authorization: `Bearer ${this.facadeService.navigationStateService.getCurrentState().Token}`,
				},
			});
		}
	}

	private handleSuccess({ body }: HttpResponse<any>): void {
		if (body) {
			const response = body as ResponseAction<any>;
			const keepSessionKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.KeepSession}`;
			const keepSession = this.facadeService.sessionStorageService.getObject(keepSessionKey); //	AUTO PARSE STRING "false" TO BOOLEAN VALUE

			if (!!response.SessionToken) {
				const sessionTokenKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.SessionToken}`;
				if (!!keepSession) {
					this.facadeService.localStorageService.setString(sessionTokenKey, response.SessionToken);
				} else {
					this.facadeService.sessionStorageService.setString(sessionTokenKey, response.SessionToken);
				}
			}
		}
	}

	private handleError(error: HttpErrorResponse, prevRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
		this.store.dispatch(setViewDataLoaded());

		return this.handle4xxError(error, prevRequest, next) || this.handleUnknownErrors(error) || EMPTY;
	}

	private trimValues(body: any) {
		const newValue = Array.isArray(body) ? [...body] : { ...body };
		Object.entries(newValue).forEach(([key, value]) => {
			if (value === null || value === undefined) {
				return;
			}
			switch (typeof value) {
				case "string":
					newValue[key] = value.trim();
					break;
				case "object":
					newValue[key] = this.trimValues(newValue[key]);
					break;
			}
		});
		return newValue;
	}

	private handle4xxError(error: HttpErrorResponse, prevRequest: HttpRequest<any>, next: HttpHandler): Observable<any> | null {
		if (Math.floor(error.status / 100) !== 4) {
			return null;
		}
		const bodyError: ResponseAction = error.error;

		if (error.status === 401 && !bodyError) {
			this.store.dispatch(
				navigateToError({
					Action: Action.InvalidTokenError,
					Data: null,
					Error: {
						Code: ErrorCodes.InvalidTokenError,
						Message: "Invalid or expired token",
					} as NOSIDError,
				} as ResponseAction)
			);
			return EMPTY;
		} else if (error.status === 403 && !bodyError) {
			const sessionTokenKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.SessionToken}`;
			const path = new URL(prevRequest.url).pathname;
			const hasSessiontoken =
				!!this.facadeService.localStorageService.getString(sessionTokenKey) || !!this.facadeService.sessionStorageService.getString(sessionTokenKey);
			if (path === this.facadeService.configurationService.APIConfiguration.AA.Authorize && hasSessiontoken) {
				// cleanup session token, since it might be invalid and we can request again authorize requests
				this.facadeService.localStorageService.remove(sessionTokenKey);
				this.facadeService.sessionStorageService.remove(sessionTokenKey);

				return this.buildRequestHandler(
					prevRequest.clone({
						setHeaders: {
							Authorization: "Bearer ", // clone request without token
						},
					}),
					next
				);
			}

			this.store.dispatch(
				navigateToError({
					Action: Action.ForbidenTokenError,
					Data: null,
					Error: {
						Code: ErrorCodes.ForbidenTokenError,
						Message: "Token not authorized for given operation",
					} as NOSIDError,
				} as ResponseAction)
			);
			return EMPTY;
		}

		return of(
			new HttpResponse({
				headers: error.headers,
				status: 200,
				statusText: error.statusText,
				url: error.url,
				body: bodyError,
			})
		);
	}

	private handleUnknownErrors(error: HttpErrorResponse): Observable<any> | null {
		if (Math.floor(error.status / 100) !== 5) {
			return null;
		}
		const bodyError = error.error
			? ({ ...error.error, IsInternalError: true } as NOSIDError)
			: ({
					Code: ErrorCodes.UnknownError,
					IsInternalError: true,
			  } as NOSIDError);

		this.store.dispatch(
			navigateToError({
				Action: Action.UnknownError,
				Data: null,
				Error: bodyError,
			} as ResponseAction)
		);

		return EMPTY;
	}
}
