import { PasswordRequest } from "#baseUrl/models/dto/outbound/password-request.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { AppState } from "../../state/app-state";
import { ConfigurationService } from "../configuration.service";
import { SignUpService } from "./signup.service";

@Injectable()
export class SocialNetworkService extends SignUpService {
	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		captcha: ReCaptchaV3Service
	) {
		super(httpService, configurationService, store, captcha);
	}

	associateAccount() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SocialNetworkAssociateAccount}`;
		return this.doGet(url);
	}

	validatePassword(password: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SocialNetworkAssociatePassword}`;

		const body: PasswordRequest = { Password: password };

		return this.doPost(url, body);
	}
}
