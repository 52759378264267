export enum ModuleName {
	AccountAssociation = "AccountAssociation",
	AccountProtection = "AccountProtection",
	AccountSelection = "AccountSelection",
	Authentication = "Authentication",
	MandatoryEmail = "MandatoryEmail",
	PasswordRecovery = "PasswordRecovery",
	PrivacyPolicies = "PrivacyPolicies",
	SignUp = "SignUp",
	NosId = "NosId",
	SocialNetworks = "SocialNetworks",
	TermsConsents = "TermsConsents",
	Generic = "Generic",
	EntryRoutes = "EntryRoutes",
	TwoFactorAuthentication = "TwoFactorAuthentication",
	ProfileSelection = "ProfileSelection",
	DeviceCode = "DeviceCode",
}
