import { FacadeService } from "#baseUrl/services/facade.service";
import { DynamicComponent } from "#baseUrl/shared/dynamics/dynamic.component";
import { AppState } from "#baseUrl/state/app-state";
import { hideSnackBar } from "#baseUrl/state/snackbar/snackbar-actions";
import { selectSnackBar, SnackBarType } from "#baseUrl/state/snackbar/snackbar-state";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { SignInRoutes } from "../../models/configurations/routing/signInRoutes";

@Component({
	selector: "nosid-snackbar",
	template: `<ng-container
		*ngIf="template"
		appDynamicComponent
		[appDynamicComponentSelector]="template.Selector"
		[appDynamicComponentHtml]="template.Html"
		[appDynamicComponentStyle]="template.Style"
		[appDynamicComponentContext]="{ data: data }"
	></ng-container>`,
})
export class SnackBarComponent extends DynamicComponent implements OnInit {

	constructor(store: Store<AppState>, facadeService: FacadeService) {
		super(store, SignInRoutes.NosId.SnackBar, facadeService);
	}

	async ngOnInit() {
		this.dismiss();

		await super.ngOnInit();

		this.data = {
			SnackBarType,
			snackBar$: this.store.select(selectSnackBar),
			dismiss: this.dismiss.bind(this),
		};
	}

	private dismiss() {
		this.store.dispatch(hideSnackBar());
	}
}
