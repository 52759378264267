import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { SelectAccount } from "#baseUrl/models/dto/outbound/selectAccount.model";
import { AppState } from "../../state/app-state";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class AccountSelectionService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	selectAccount(id: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountSelectionSelectAccount}`;
		const body: SelectAccount = { Value: id };

		return this.doPost(url, body);
	}
}
