import { ModuleName } from "./module-name.enum";
import { Screen } from "./screen-name.enum";

export class SignInRoutes {
	static NosId = class {
		static get NavBar() {
			return `${ModuleName.NosId}|NavBar`;
		}

		static get Popup() {
			return `${ModuleName.NosId}|Popup`;
		}

		static get SnackBar() {
			return `${ModuleName.NosId}|SnackBar`;
		}

		static get PasswordInput() {
			return `${ModuleName.NosId}|PasswordInput`;
		}

		static get SelectInput() {
			return `${ModuleName.NosId}|SelectInput`;
		}

		static get Input() {
			return `${ModuleName.NosId}|Input`;
		}

		static get PhoneInput() {
			return `${ModuleName.NosId}|PhoneInput`;
		}
	};

	static EntryRoutes = class {
		static get Authorize() {
			return `${ModuleName.EntryRoutes}|${Screen.Authorize}`;
		}
	};

	static Authentication = class {
		static get Username() {
			return `${ModuleName.Authentication}|${Screen.Username}`;
		}

		static get SinglePageLogin() {
			return `${ModuleName.Authentication}|${Screen.SinglePageLogin}`;
		}

		static get CountryCodeConfirmation() {
			return `${ModuleName.Authentication}|${Screen.CountryCodeConfirmation}`;
		}

		static get Password() {
			return `${ModuleName.Authentication}|${Screen.Password}`;
		}

		static get UserBlocked() {
			return `${ModuleName.Authentication}|${Screen.UserBlocked}`;
		}
	};

	static MandatoryEmail = class {
		static get LandingPage() {
			return `${ModuleName.MandatoryEmail}|${Screen.LandingPage}`;
		}

		static get ChangeContact() {
			return `${ModuleName.MandatoryEmail}|${Screen.ChangeContact}`;
		}

		static get Complete() {
			return `${ModuleName.MandatoryEmail}|${Screen.Complete}`;
		}
	};

	static SocialNetworks = class {
		static get Username() {
			return `${ModuleName.SocialNetworks}|${Screen.Username}`;
		}

		static get Password() {
			return `${ModuleName.SocialNetworks}|${Screen.Password}`;
		}

		static get LandingPage() {
			return `${ModuleName.SocialNetworks}|${Screen.LandingPage}`;
		}

		static get CountryCodeConfirmation() {
			return `${ModuleName.SocialNetworks}|${Screen.CountryCodeConfirmation}`;
		}

		static get Complete() {
			return `${ModuleName.SocialNetworks}|${Screen.Complete}`;
		}
	};

	static AccountAssociation = class {
		static get LandingPage() {
			return `${ModuleName.AccountAssociation}|${Screen.LandingPage}`;
		}

		static get HolderInput() {
			return `${ModuleName.AccountAssociation}|${Screen.HolderInput}`;
		}

		static get CodeInput() {
			return `${ModuleName.AccountAssociation}|${Screen.CodeInput}`;
		}

		static get Confirm() {
			return `${ModuleName.AccountAssociation}|${Screen.Confirm}`;
		}

		static get SendSms() {
			return `${ModuleName.AccountAssociation}|${Screen.SendSms}`;
		}

		static get ContactSelection() {
			return `${ModuleName.AccountAssociation}|${Screen.ContactSelection}`;
		}
	};

	static TermsConsents = class {
		static get Terms() {
			return `${ModuleName.TermsConsents}|${Screen.Terms}`;
		}

		static get Consents() {
			return `${ModuleName.TermsConsents}|${Screen.Consents}`;
		}

		static get PrivacyPolicies() {
			return `${ModuleName.TermsConsents}|${Screen.PrivacyPolicies}`;
		}
	};

	static AccountSelection = class {
		static get SelectAccount() {
			return `${ModuleName.AccountSelection}|${Screen.SelectAccount}`;
		}
	};

	static AccountProtection = class {
		static get LandingPage() {
			return `${ModuleName.AccountProtection}|${Screen.LandingPage}`;
		}

		static get CodeInput() {
			return `${ModuleName.AccountProtection}|${Screen.CodeInput}`;
		}

		static get SendSms() {
			return `${ModuleName.AccountProtection}|${Screen.SendSms}`;
		}

		static get Complete() {
			return `${ModuleName.AccountProtection}|${Screen.Complete}`;
		}

		static get ChangeContact() {
			return `${ModuleName.AccountProtection}|${Screen.ChangeContact}`;
		}

		static get RollbackComplete() {
			return `${ModuleName.AccountProtection}|${Screen.RollbackComplete}`;
		}
	};

	static PasswordRecovery = class {
		static get LandingPage() {
			return `${ModuleName.PasswordRecovery}|${Screen.LandingPage}`;
		}

		static get CountryCodeConfirmation() {
			return `${ModuleName.PasswordRecovery}|${Screen.CountryCodeConfirmation}`;
		}

		static get CodeInput() {
			return `${ModuleName.PasswordRecovery}|${Screen.CodeInput}`;
		}

		static get SendSms() {
			return `${ModuleName.PasswordRecovery}|${Screen.SendSms}`;
		}

		static get ContactSelection() {
			return `${ModuleName.PasswordRecovery}|${Screen.ContactSelection}`;
		}

		static get NewPassword() {
			return `${ModuleName.PasswordRecovery}|${Screen.NewPassword}`;
		}

		static get Complete() {
			return `${ModuleName.PasswordRecovery}|${Screen.Complete}`;
		}

		static get PasswordStatusPending() {
			return `${ModuleName.PasswordRecovery}|${Screen.PasswordStatusPending}`;
		}

		static get PasswordRecoveryMandatory() {
			return `${ModuleName.PasswordRecovery}|${Screen.MandatoryPasswordRecovery}`;
		}
	};

	static SignUp = class {
		static get LandingPage() {
			return `${ModuleName.SignUp}|${Screen.LandingPage}`;
		}

		static get SendSms() {
			return `${ModuleName.SignUp}|${Screen.SendSms}`;
		}

		static get ChangeContact() {
			return `${ModuleName.SignUp}|${Screen.ChangeContact}`;
		}

		static get CodeInput() {
			return `${ModuleName.SignUp}|${Screen.CodeInput}`;
		}

		static get Complete() {
			return `${ModuleName.SignUp}|${Screen.Complete}`;
		}

		static get EmailValidated() {
			return `${ModuleName.SignUp}|${Screen.EmailValidated}`;
		}

		static get DigitalBirthLandingPage() {
			return `${ModuleName.SignUp}|${Screen.DigitalBirthLandingPage}`;
		}

		static get DigitalBirthComplete() {
			return `${ModuleName.SignUp}|${Screen.DigitalBirthComplete}`;
		}

		static get SocialLandingPage() {
			return `${ModuleName.SignUp}|${Screen.SocialLandingPage}`;
		}

		static get SocialComplete() {
			return `${ModuleName.SignUp}|${Screen.SocialComplete}`;
		}
	};

	static TwoFactorAuthentication = class {
		static get CodeInput() {
			return `${ModuleName.TwoFactorAuthentication}|${Screen.CodeInput}`;
		}
		static get Promote() {
			return `${ModuleName.TwoFactorAuthentication}|${Screen.Promote}`;
		}
	};

	static Generic = class {
		static get GenericCountryCodeConfirmation() {
			return `${ModuleName.Generic}|${Screen.CountryCodeConfirmation}`;
		}

		static get Error() {
			return `${ModuleName.Generic}|${Screen.ScreenError}`;
		}

		static get UnauthorizedUser() {
			return `${ModuleName.Generic}|${Screen.UnauthorizedUser}`;
		}

		static get UnauthorizedApp() {
			return `${ModuleName.Generic}|${Screen.UnauthorizedApp}`;
		}

		static get PermissionRequested() {
			return `${ModuleName.Generic}|${Screen.PermissionRequested}`;
		}

		static get Forbidden() {
			return `${ModuleName.Generic}|${Screen.Forbidden}`;
		}
	};

	static ProfileSelection = class {
		static get SelectProfile() {
			return `${ModuleName.ProfileSelection}|${Screen.SelectProfile}`;
		}

		static get CreateProfile() {
			return `${ModuleName.ProfileSelection}|${Screen.CreateProfile}`;
		}
	};

	static DeviceCode = class {
		static get LandingPage() {
			return `${ModuleName.DeviceCode}|${Screen.LandingPage}`;
		}

		static get SelectUser() {
			return `${ModuleName.DeviceCode}|${Screen.SelectUser}`;
		}

		static get Complete() {
			return `${ModuleName.DeviceCode}|${Screen.Complete}`;
		}
	};
}
