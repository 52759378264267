import { ExternalLoginProviders } from "#baseUrl/models/configurations/external-login/login-providers.enum";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded } from "#baseUrl/state/current-view/current-view-actions";
import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigurationService } from "../configuration.service";
import { ExternalLoginService } from "./external-login.service";

type FacebookClient = facebook.FacebookStatic;

@Injectable()
export class FacebookLoginService extends ExternalLoginService {
	private readonly oAuthClient: Promise<FacebookClient>;

	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		protected readonly zone: NgZone
	) {
		super(httpService, configurationService, store, zone);
	}

	login(): void {
		this.oAuthClient.then((client) => {
			client.login(
				(response) => {
					if (response.status === "connected") {
						this.finishAuthentication(ExternalLoginProviders.Facebook, response.authResponse.accessToken);
					} else {
						this.store.dispatch(setViewDataLoaded());
					}
				},
				{scope: this.configurationService.ExternalLoginProviders.Facebook.Scope}
			);
		});
	}

	logout(): void {
		this.oAuthClient.then((client) => {
			client.logout();
		});
	}

	initProvider(): Promise<FacebookClient> {
		return new Promise((resolve) => {
			//	Async load facebook sdk script
			const node = document.createElement("script");
			node.src = this.configurationService.ExternalLoginProviders.Facebook.API;
			node.async = true;

			// 	Retrieve the singleton for the FB library on load script
			node.onload = () => {
				FB.init({
					appId: this.configurationService.ExternalLoginProviders.Facebook.AppId,
					cookie: false,
					version: this.configurationService.ExternalLoginProviders.Facebook.Version,
				});

				resolve(FB);
			};

			node.onerror = () => {
				resolve();
			};

			//	Inject script into head element
			document.getElementsByTagName("head")[0].appendChild(node);
		});
	}
}
