import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { PasswordComponent } from "../../features/authentication/password/password.component";

@Injectable()
export class CanDeactivatePasswordGuardService implements CanDeactivate<PasswordComponent> {
	canDeactivate(
		component: PasswordComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean {
		//	index of ("unauthorized").indexOf("authorize") is greater than 0... unauthorized urls must be allowed
		return nextState && (nextState.url.indexOf("unauthorized") >= 0 || nextState.url.indexOf("authorize") < 0);
	}
}
