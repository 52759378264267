import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NavigationDirectionEnum } from "#baseUrl/models/navigation/navigation-direction.enum";

export interface NavigationState {
	Direction: NavigationDirectionEnum;
}

export const navigationFeatureSelector = createFeatureSelector("Navigation");

export const selectNavigationDirection = createSelector(navigationFeatureSelector, (state: NavigationState) => {
	return state.Direction;
});
