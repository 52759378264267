import { createAction, props } from "@ngrx/store";
import { ResponseAction } from "../../models/dto/response-action.model";

export const NAVIGATE_TO = "[NAVIGATE] Navigate To";

export const NAVIGATE_TO_ERROR = "[NAVIGATE] Navigate To Error";

export const NAVIGATE_BACKWARD = "[NAVIGATE] Navigate Backward";

export const NAVIGATE_REFRESH = "[NAVIGATE] Navigate Refresh";

export const NAVIGATE_FORWARD = "[NAVIGATE] Navigate Forward";

export const navigateTo = createAction(NAVIGATE_TO, props<ResponseAction<any>>());

export const navigateToError = createAction(NAVIGATE_TO_ERROR, props<ResponseAction<any>>());

export const navigateBackward = createAction(NAVIGATE_BACKWARD);

export const navigateRefresh = createAction(NAVIGATE_REFRESH);

export const navigateForward = createAction(NAVIGATE_FORWARD);
