import { ErrorCodes } from "#baseUrl/models/dto/inbound/error/errors.enum";
import { NOSIDError } from "#baseUrl/models/dto/inbound/error/nosid-error";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { Action } from "#baseUrl/models/navigation/action.enum";
import { AppState } from "#baseUrl/state/app-state";
import { navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { FacadeService } from "../facade.service";
import { UtilsService } from "../utils.service";
import { NavigationStateService } from "./navigation-state.service";

@Injectable()
export class CanActivateGuardService implements CanActivate {
	constructor(
		private readonly facadeService: FacadeService,
		private readonly navigationStateService: NavigationStateService,
		private readonly store: Store<AppState>
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		if (route.routeConfig.path === "logout") {
			return this.processLogoutRoute(route);
		}

		return this.processNormalRoute(route);
	}

	private processLogoutRoute(route: ActivatedRouteSnapshot): Observable<boolean> {
		let uri =
			route.queryParamMap.get("redirect_uri") ||
			route.queryParamMap.get("post_logout_redirect_uri") ||
			this.facadeService.configurationService.NosIdPortalLink;
		uri = this.facadeService.utilsService.sanitizeUrl(uri, this.facadeService.configurationService.NosIdPortalLink);

		const redirectUri = decodeURIComponent(uri);

		return this.facadeService.authenticationService.logout(redirectUri);
	}

	private processNormalRoute(route: ActivatedRouteSnapshot): Observable<boolean> {
		const currentState = this.navigationStateService.getCurrentState();
		//	ALL OTHER ROUTES
		if (
			!currentState ||
			!route.data.ValidActions.some((action: Action) => {
				return action === currentState.Action;
			})
		) {
			this.store.dispatch(
				navigateToError({
					Action: Action.ForbidenTokenError,
					Data: null,
					Error: {
						Code: ErrorCodes.ForbidenTokenError,
						Message: "Cannot navigate to that page",
					} as NOSIDError,
				} as ResponseAction)
			);

			return of(false);
		}

		return of(true);
	}
}
