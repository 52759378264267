import { AbstractControl, Validators } from "@angular/forms";

const reg =
	/^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
	if (Validators.required(control) !== null) {
		return Validators.required(control);
	}

	const err = { Email: true };

	if (!reg.test(control.value?.toString() ?? "")) {
		return err;
	}

	const [, emailDomain]: string[] = control.value.toString().split("@");

	if (!emailDomain.includes(".") || emailDomain.split(".").filter((d) => d.length).length < 2) {
		return err;
	}

	return null;
}
