import { EventParameters } from "#baseUrl/models/analytics/event-parameters";
import { EventType } from "#baseUrl/models/analytics/event-type.enum";
import { Flow } from "#baseUrl/models/analytics/flow.enum";
import { LoggerService } from "#baseUrl/services/logging/logger.service";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { map, tap } from "rxjs/operators";
import { AppState } from "../app-state";
import {
	SEND_EXCEPTION_EVENT,
	SEND_LEAVE_VIEW_EVENT,
	SEND_SCREEN_ACTION_EVENT,
	SEND_SCREEN_NAVIGATION_EVENT,
	SET_CURRENT_PAGE,
	setCurrentPageCompleted,
} from "./logger-actions";
import { LoggerState, selectLogger } from "./logger-state";

@Injectable()
export class LoggerEffects {
	setCurrentPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SET_CURRENT_PAGE),
			map(({routerKey}: { routerKey: string }) => {
				const routerKeyValues = routerKey.split("|");
				const step = routerKeyValues[0];
				const screen = routerKeyValues[1];
				return {Step: step, Screen: screen} as LoggerState;
			}),
			tap((loggerState: LoggerState) => {
				this.loggerService.logEvent(EventType.EnterView, {
					Flow: Flow.SignIn,
					Step: loggerState.Step,
					Screen: loggerState.Screen,
				} as EventParameters);
			}),
			map((loggerState: LoggerState) => {
				return setCurrentPageCompleted({loggerState});
			})
		)
	);
	sendLeaveViewEvent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SEND_LEAVE_VIEW_EVENT),
				tap(() => {
					this.store
						.pipe(select(selectLogger))
						.subscribe((state: LoggerState) => {
							this.loggerService.logEvent(EventType.LeaveView, {
								Flow: Flow.SignIn,
								Step: state.Step,
								Screen: state.Screen,
							} as EventParameters);
						})
						.unsubscribe();
				})
			),
		{dispatch: false}
	);
	sendScreenActionEvent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SEND_SCREEN_ACTION_EVENT),
				tap(({eventParameters}: { eventParameters: EventParameters }) => {
					this.store
						.pipe(select(selectLogger))
						.subscribe((state: LoggerState) => {
							this.logActionEvent(EventType.ScreenAction, state.Step, state.Screen, eventParameters);
						})
						.unsubscribe();
				})
			),
		{dispatch: false}
	);
	sendScreenNavigationEvent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SEND_SCREEN_NAVIGATION_EVENT),
				tap(({eventParameters}: { eventParameters: EventParameters }) => {
					this.store
						.pipe(select(selectLogger))
						.subscribe((state: LoggerState) => {
							this.logActionEvent(EventType.ScreenNavigation, state.Step, state.Screen, eventParameters);
						})
						.unsubscribe();
				})
			),
		{dispatch: false}
	);
	sendExceptionEvent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SEND_EXCEPTION_EVENT),
				tap(({eventParameters}: { eventParameters: EventParameters }) => {
					this.store
						.pipe(select(selectLogger))
						.subscribe((state: LoggerState) => {
							this.loggerService.logEvent(EventType.Exception, {
								Flow: Flow.SignIn,
								Step: state.Step,
								Screen: state.Screen,
								ActionType: eventParameters.ActionType,
								Option: eventParameters.Option || null,
								ExceptionType: eventParameters.ExceptionType,
								ExceptionMessage: eventParameters.ExceptionMessage,
							} as EventParameters);
						})
						.unsubscribe();
				})
			),
		{dispatch: false}
	);

	constructor(private readonly actions$: Actions, private readonly store: Store<AppState>, private readonly loggerService: LoggerService) {
	}

	private logActionEvent(eventType: EventType, step: string, screen: string, eventParameters: EventParameters): void {
		this.loggerService.logEvent(eventType, {
			Flow: Flow.SignIn,
			Step: step,
			Screen: screen,
			ActionType: eventParameters.ActionType,
			Option: eventParameters.Option || null,
		} as EventParameters);
	}
}
