export enum ActionType {
	Submit = "Submit",
	Send = "Send",
	ResendCode = "ResendCode",
	Back = "Back",
	Next = "Next",
	PasswordRecovery = "PasswordRecovery",
	Portal = "Portal",
	Signup = "Signup",
	SocialNetworks = "SocialNetworks",
	EmailValidation = "EmailValidation",
	ChangeAccount = "ChangeAccount",
	Cancel = "Cancel",
	Skip = "Skip",
	ChangeContact = "ChangeContact",
	RollbackChangeContact = "RollbackChangeContact",
}
