import { createReducer, on } from "@ngrx/store";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { setViewDataLoaded, setViewDataLoading, updateToken, updateViewData } from "./current-view-actions";
import { CurrentViewState } from "./current-view-state";

export const initialState = {
	HasError: false,
	Loaded: false,
	Loading: false,
	ViewData: null,
} as CurrentViewState;

const reducer = createReducer(
	initialState,

	on(updateToken, (currentState: CurrentViewState, payload: ResponseAction<any>) => {
		return {
			...currentState,
			ViewData: {
				...currentState.ViewData,
				Token: payload.Token,
			},
		};
	}),

	on(updateViewData, (currentState: CurrentViewState, payload: ResponseAction<any>) => {
		//	WORKING WITH INITIAL STATE
		if (!currentState.ViewData) {
			return {
				HasError: !!payload.Error,
				Loading: false,
				Loaded: true,
				ViewData: {
					...payload,
				} as ResponseAction<any>,
			} as CurrentViewState;
		}

		//	NOT INITIAL STATE
		if (!payload.Error) {
			return {
				HasError: false,
				Loading: false,
				Loaded: true,
				ViewData: payload,
			} as CurrentViewState;
		}

		/*
		There are 2 types of error screens:
		- 	Ones that are on the same screen (responses 200ish with Error property) that originated the error;

		- 	Ones that are on error screens like missing state or generic error.

		To ensure that we always have the ViewData completed we need to evaluate the action that arrived
		*/

		return {
			HasError: true,
			Loading: false,
			Loaded: true,
			ViewData: {
				...payload,
				Data: payload.Action === currentState.ViewData.Action ? currentState.ViewData : payload.Data,
			} as ResponseAction<any>,
		} as CurrentViewState;
	}),

	on(setViewDataLoading, (currentState: CurrentViewState) => {
		return {
			...currentState,
			Loading: true,
			Loaded: false,
		};
	}),

	on(setViewDataLoaded, (currentState: CurrentViewState) => {
		return {
			...currentState,
			Loading: false,
			Loaded: true,
		};
	})
);

export function currentViewReducer(state, action) {
	return reducer(state, action);
}
