import { EnvironmentConfigurationEnum } from "#baseUrl/models/configurations/environment/environmentConfiguration.enum";
import { FacadeService } from "#baseUrl/services/facade.service";
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
	constructor(private readonly titleService: Title, private readonly translate: TranslateService, private readonly facadeService: FacadeService) {}

	private get isDevEnvironment(): boolean {
		return this.facadeService.configurationService.Environment === EnvironmentConfigurationEnum.DEV;
	}

	ngOnInit(): void {
		this.setTitle();

		this.setAnimation();

		this.setLanguage();

		this.setFavIcon();
	}

	private setTitle(): void {
		if (this.facadeService.templatesConfigurationService.AppTitle) {
			this.titleService.setTitle(this.facadeService.templatesConfigurationService.AppTitle);
		}
	}

	private setAnimation(): void {
		const routerAnimations = document.getElementById("route-animations");
		if (routerAnimations && this.facadeService.templatesConfigurationService.AnimationCSSPath) {
			routerAnimations.setAttribute("href", this.facadeService.templatesConfigurationService.AnimationCSSPath);
		}
	}

	private setLanguage(): void {
		this.translate.addLangs(this.facadeService.templatesConfigurationService.ClientTemplate.I18n.AvailableLanguages);
		this.translate.setDefaultLang(this.facadeService.templatesConfigurationService.ClientTemplate.I18n.DefaultLanguage);
		this.translate.use(this.facadeService.templatesConfigurationService.ClientTemplate.I18n.DefaultLanguage);
	}

	private setFavIcon(): void {
		const faviconElem = document.getElementById("favicon");
		if (faviconElem && this.facadeService.templatesConfigurationService.FavIcon) {
			faviconElem.setAttribute("href", this.facadeService.templatesConfigurationService.FavIcon);
		}
	}
}
