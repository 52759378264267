import { FacadeService } from "#baseUrl/services/facade.service";
import { DynamicComponent } from "#baseUrl/shared/dynamics/dynamic.component";
import { AppState } from "#baseUrl/state/app-state";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { SignInRoutes } from "../../models/configurations/routing/signInRoutes";
import { selectLoading } from "../../state/current-view/current-view-state";

@Component({
	selector: "nosid-nav-bar",
	template: `
		<ng-container
			*ngIf="template"
			appDynamicComponent
			[appDynamicComponentSelector]="template.Selector"
			[appDynamicComponentHtml]="template.Html"
			[appDynamicComponentStyle]="template.Style"
			[appDynamicComponentContext]="{ data: data }"
		></ng-container>`,
})
export class NavBarComponent extends DynamicComponent implements OnInit, OnDestroy {
	loading$: Subscription;

	constructor(store: Store<AppState>, facadeService: FacadeService) {
		super(store, SignInRoutes.NosId.NavBar, facadeService);
	}

	async ngOnInit() {
		await super.ngOnInit();
		this.data = {
			loading: false,
		};

		this.loading$ = this.store.select(selectLoading).subscribe((loading) => {
			this.data.loading = loading;
			if (loading) {
				document.body.setAttribute("style", "overflow:hidden;");
			} else {
				setTimeout(() => {
					document.body.setAttribute("style", "overflow:visible;");
				}, 500);
			}
		});
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();

		if (this.loading$) {
			this.loading$.unsubscribe();
		}
	}
}
