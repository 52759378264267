import { Injectable } from "@angular/core";
import { CanLoad, Route, UrlSegment } from "@angular/router";
import { Observable, of } from "rxjs";
import { DynamicFactory } from "@nosinovacao/nosid-mfe-common";

@Injectable()
export class CanLoadGuard implements CanLoad {
	constructor(private readonly dynamicFactory: DynamicFactory) {
	}

	canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
		this.dynamicFactory.preloadModuleComponents(route.data.ModuleName);
		return of(true);
	}
}
