import { Injectable } from "@angular/core";
import { NavigationStart, Router, RouterEvent } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { ErrorCodes } from "#baseUrl/models/dto/inbound/error/errors.enum";
import { NOSIDError } from "#baseUrl/models/dto/inbound/error/nosid-error";
import { NavigationMap } from "#baseUrl/models/navigation/navigationMap";
import { ConfigurationService } from "#baseUrl/services/configuration.service";
import { NavigationStateService } from "#baseUrl/services/navigation/navigation-state.service";
import { UtilsService } from "#baseUrl/services/utils.service";
import { ResponseAction } from "../../models/dto/response-action.model";
import { Action } from "../../models/navigation/action.enum";
import { AppState } from "../app-state";
import { updateViewData } from "../current-view/current-view-actions";
import { showSnackBar } from "../snackbar/snackbar-actions";
import { SnackBarState, SnackBarType } from "../snackbar/snackbar-state";
import { NAVIGATE_TO, NAVIGATE_TO_ERROR, navigateBackward, navigateForward, navigateRefresh, navigateTo, navigateToError } from "./navigation-actions";
import { SessionStorageService } from "@nosinovacao/nosid-mfe-common";
import { ContactRollbackSuccess } from "#baseUrl/models/dto/inbound/success/rollbackSuccess.model";

@Injectable()
export class NavigationEffects {
	navigateToError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(NAVIGATE_TO_ERROR),
				tap((res: ResponseAction<any>) => {
					//	ERRORS CAN ONLY BE WITH INVALID REQUEST CODE ACTION OR WITH SAME CODE ACTION AS CURRENT COMPONENT VIEW
					//	ALL INVALID REQUESTS ARE REDIRECTED TO GENERIC ERROR VIEW
					//	ERRORS WITH SAME CODE ACTION AS CURRENT VIEW SHOULD BE HANDLED IN CURRENT VIEW
					//	IS INTERNAL ERROR MUST ALWAYS THROW A SNACKBAR. OTHERWISE MIHAIL WILL KICK ME
					//	CANNOT SEND SECURITY CODE THROW A SNACKBAR AS WELL
					// IF ERROR CODE IS CHALLENGE, UPDATE VIEW DATA WITH ERROR AND RETURN
					if (res.Error.Code === ErrorCodes.Challenge) {
						const st = this.navigationStateService.getCurrentState();
						this.store.dispatch(
							updateViewData({
								...st,
								Error: {
									Code: res.Error.Code,
								} as NOSIDError,
							})
						);
						return;
					}

					//	HANDLE INTERNAL ERRORS AND CANNOT SEND SECURITY CODE
					if (res.Error.Code === ErrorCodes.NonLegitimateUser) {
						this.handleSnackbarError(res);
						const st = this.navigationStateService.getCurrentState();
						this.store.dispatch(
							updateViewData({
								...st,
								Error: {
									Code: ErrorCodes.Challenge,
								} as NOSIDError,
							})
						);
						return;
					}
					if (res.Error.Code === ErrorCodes.ContactsRollbackIsNotValidAnymore) {
						this.store.dispatch(
							navigateTo({
								...res,
								Action: Action.RollbackUserContacts,
								Data: {
									TwoFactorEnable: false,
									alreadyRollback: true,
								} as ContactRollbackSuccess,
							})
						);
						return;
					}

					//	REDIRECT TO GENERIC ERROR VIEW
					if (res.Action === Action.InvalidRequest || res.Action === Action.InvalidTokenError || res.Action === Action.ForbidenTokenError) {
						this.store.dispatch(navigateTo(res));
						return;
					}

					//	HANDLE INTERNAL ERRORS AND CANNOT SEND SECURITY CODE
					if (
						res.Error.IsInternalError ||
						res.Error.Code === ErrorCodes.CannotSendSecurityCode ||
						res.Error.Code === ErrorCodes.NotFoundSecurityCode
					) {
						this.handleSnackbarError(res);
						return;
					}

					if (this.navigationStateService.getCurrentState()?.Action === res.Action) {
						//	SHOW ERROR IN SAME VIEW
						res = {
							...res,
							Token: this.navigationStateService.getCurrentState()?.Token,
						};

						this.store.dispatch(updateViewData(res));
						return;
					}

					//	HANDLE ERRORS WITH SPECIFIC CODE ACTIONS LIKE USERBLOCKED... THEY ARE ERRORS WITH CODE ACTIONS SPECIFIC
					this.store.dispatch(navigateTo(res));
				})
			),
		{ dispatch: false }
	);
	private navigationSubscription: Subscription;
	navigate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(NAVIGATE_TO),
				tap((res: ResponseAction<any>) => {
					if (this.navigationSubscription) {
						this.navigationSubscription.unsubscribe();
					}
					//	TO CONTROL IN APP NAVIGATION (NO ARROWS BROWSER)
					this.navigationSubscription = this.router.events
						.pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
						.subscribe((event: NavigationStart) => {
							if (event.navigationTrigger === "imperative") {
								this.store.dispatch(navigateForward());
								this.navigationStateService.saveNavigation(event, res);
							}
							this.navigationSubscription.unsubscribe();
							this.navigationSubscription = null;
						});
				}),
				tap((res: ResponseAction<any>) => {
					//	HANDLE REDIRECT ACTION
					if (res.Action === Action.RedirectTo) {
						this.handleRedirect(res);
						return;
					}

					//	ERRORS WITHIN SAME VIEW MUST CHECK THE NAVIGATION MAP FIRST
					const path = !res.Error ? NavigationMap.getRoutePath(res.Action) : NavigationMap.getRoutePath(res.Action) || "/generic/error";

					if (!path) {
						console.error("Could not link CODE_ACTION to navigate to view", res);
						return;
					}

					//	NEED TO ADD / TO THE PATH TO MATCH EVENT URL
					const hash = window.btoa(JSON.stringify({ path, date: Date.now() }));

					this.router.navigate([path], { fragment: hash }).then((x) => {
						this.store.dispatch(updateViewData(res));
					});
				})
			),
		{ dispatch: false }
	);

	constructor(
		private readonly actions$: Actions,
		private readonly router: Router,
		private readonly navigationStateService: NavigationStateService,
		private readonly store: Store<AppState>,
		private readonly translationService: TranslateService,
		private readonly configurationService: ConfigurationService,
		private readonly sessionStorage: SessionStorageService,
		private readonly utilsService: UtilsService
	) {
		//	TO CONTROL BROWSER ARROWS NAVIGATION
		this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
			//	##################    POPSTATE NAVIGATIONS    ##################

			//	BACK, FORWARD
			if (event.navigationTrigger === "popstate" && event.restoredState) {
				this.handleBackAndForwardNavigations(event);
				return;
			}

			//	##################    IMPERATIVE NAVIGATIONS    ##################

			//	HANDLE START FLOWS (LOGOUT, AUTHORIZE, EMAIL VERIFY, ETC...)
			if (this.navigationStateService.isInitState() || NavigationMap.isEntryRoute(event.url)) {
				return;
			}

			//	HANDLE REFRESH
			if (event.navigationTrigger === "imperative" && event.url === this.navigationStateService.getCurrentUrl()) {
				this.handleRefresh();
				return;
			}

			//	HANDLE ALL OTHER NAVIGATIONS (INCLUDING HASH CHANGES)
			this.handleNormalNavigation(event);
		});
	}

	private handleSnackbarError(res: ResponseAction) {
		const errorIdKey = `Errors.${res.Error.Code}.Id`;
		const errorMessageKey = `Errors.${res.Error.Code}.Message`;
		const errorId = this.translationService.instant(errorIdKey);
		const errorMessage = this.translationService.instant(errorMessageKey);

		this.store.dispatch(
			showSnackBar({
				Translatable: {
					Key: `Common.SnackBar.Generic.Error`,
					Params: {
						Id: errorId !== errorIdKey ? errorIdKey : "Errors.DefaultError.Id",
						Message: errorMessage !== errorMessageKey ? errorMessageKey : "Errors.DefaultError.Message",
					},
				},
				Type: SnackBarType.Warn,
			} as SnackBarState)
		);
	}

	private handleRedirect(res: ResponseAction<any>) {
		//	AUTHORIZE PROCESS DONE. NEED TO REMOVE AUTHORIZE PARAMS FROM SESSION STORAGE TO ALLOW OTHER PARAMS TO BE WRITTEN
		const authParamsKey = `${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.AuthorizeParams}`;
		this.sessionStorage.remove(authParamsKey);

		this.utilsService.redirect(res.Data);
	}

	private handleBackAndForwardNavigations(event: NavigationStart): void {
		//	ANIMATION DIRECTION UPDATE
		if (this.navigationStateService.isBackNavigation(event)) {
			this.store.dispatch(navigateBackward());
		}

		if (this.navigationStateService.isForwardNavigation(event)) {
			this.store.dispatch(navigateForward());
		}
		//	END ANIMATION DIRECTION UPDATE

		//	UPDATE VIEW DATA ON BACK OR FORWARD
		this.navigationStateService.popNavigation(event);
		this.store.dispatch(updateViewData(this.navigationStateService.getCurrentState()));
	}

	private handleRefresh(): void {
		this.store.dispatch(navigateRefresh());
		this.store.dispatch(updateViewData(this.navigationStateService.getCurrentState()));
	}

	private handleNormalNavigation(event: NavigationStart): void {
		//	PROTECT EDIT URL NAVIGATIONS THAT ARE NOT REFRESH
		//	HASH CONSISTS OF A JSON OBJECT ENCODED LIKE FOLLOWING
		//	const hash = window.btoa(JSON.stringify({ path:path , date: Date.now() }));
		let invalidNavigation;
		try {
			const path = event.url.split("#").shift();
			const hash = event.url.split("#").pop();
			const decodedHash = JSON.parse(atob(hash));
			invalidNavigation = decodedHash.path !== path || isNaN(+new Date(decodedHash.date));
		} catch (e) {
			invalidNavigation = true;
		}

		if (invalidNavigation) {
			//	YOU SHALL NOT PASS!
			this.store.dispatch(
				navigateToError({
					Action: Action.ForbidenTokenError,
					Data: null,
					Error: {
						Code: ErrorCodes.ForbidenTokenError,
						Message: "Token not authorized for given operation",
					} as NOSIDError,
				} as ResponseAction)
			);
		}
	}
}
