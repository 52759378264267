import { SignInRoutes } from "#baseUrl/models/configurations/routing/signInRoutes";
import { Inject, Injectable } from "@angular/core";
import { ClientTemplate } from "../models/configurations/templates/clientTemplate.interface";
import { TemplateConfiguration } from "../models/configurations/templates/templateConfiguration.interface";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class TemplatesConfigurationService {
	private readonly templatesConfig: ClientTemplate[];

	private enrichedClientTemplate: ClientTemplate = null;

	private routeMap: Map<string, TemplateConfiguration> = null;

	constructor(@Inject("templates") templatesConfig: ClientTemplate[], private readonly configurationService: ConfigurationService) {
		this.templatesConfig = templatesConfig;
	}

	get RouteMap(): Map<string, TemplateConfiguration> {
		if (this.routeMap !== null) {
			return this.routeMap;
		}

		this.routeMap = new Map<string, TemplateConfiguration>();

		for (const moduleKey of Object.keys(this.ClientTemplate.Routes)) {
			const defaultRouteModule = this.ClientTemplate.Routes[moduleKey];
			for (const componentKey of Object.keys(defaultRouteModule)) {
				this.routeMap.set(SignInRoutes[moduleKey][componentKey], this.ClientTemplate.Routes[moduleKey][componentKey]);
			}
		}

		return this.routeMap;
	}

	public get ClientTemplate(): ClientTemplate {
		if (!!this.enrichedClientTemplate) {
			return this.enrichedClientTemplate;
		}

		const specificClientTemplate =
			this.templatesConfig.find((tc) => tc.ClientId === this.configurationService.ClientId) || this.DefaultTemplateConfiguration;

		this.enrichedClientTemplate = this.getBaseClientTemplate(specificClientTemplate);

		this.enrichedClientTemplate.Firebase = specificClientTemplate.Firebase || null;

		this.enrichedClientTemplate.hideCookiebot = specificClientTemplate.hideCookiebot || null;

		return this.enrichedClientTemplate;
	}

	public get AnimationCSSPath(): string {
		return this.ClientTemplate.AnimationCSSPath;
	}

	public get AppTitle(): string {
		return this.ClientTemplate.AppTitle;
	}

	public get FavIcon(): string {
		return this.ClientTemplate.FavIcon;
	}

	private get DefaultTemplateConfiguration(): ClientTemplate {
		return this.templatesConfig.find((tc) => tc.ClientId === this.configurationService.DefaultClientId);
	}

	public getClientRoute(route: string): TemplateConfiguration {
		return this.RouteMap.get(route);
	}

	private getBaseClientTemplate(templateConfig: ClientTemplate) {
		if (templateConfig.App === "Default") {
			return this.DefaultTemplateConfiguration;
		}

		const baseClientApp = templateConfig.Extends || "Default";
		const currentBaseTemplate = this.templatesConfig.find((t) => t.App === baseClientApp);
		const enrichedBaseClientTemplate = this.getBaseClientTemplate(currentBaseTemplate);

		return {
			App: this.getValueOrDefault("App", enrichedBaseClientTemplate, templateConfig),
			Extends: this.getValueOrDefault("Extends", enrichedBaseClientTemplate, templateConfig),
			ClientId: this.getValueOrDefault("ClientId", enrichedBaseClientTemplate, templateConfig),
			AppTitle: this.getValueOrDefault("AppTitle", enrichedBaseClientTemplate, templateConfig),
			FavIcon: this.getValueOrDefault("FavIcon", enrichedBaseClientTemplate, templateConfig),
			hideCookiebot: this.getValueOrDefault("hideCookiebot", enrichedBaseClientTemplate, templateConfig),
			AnimationCSSPath: this.getValueOrDefault("AnimationCSSPath", enrichedBaseClientTemplate, templateConfig),
			FontDeclarationPath: this.getValueOrDefault("FontDeclarationPath", enrichedBaseClientTemplate, templateConfig),
			I18n: {
				Path: this.getValueOrDefault("Path", enrichedBaseClientTemplate.I18n, templateConfig.I18n),
				DefaultLanguage: this.getValueOrDefault("DefaultLanguage", enrichedBaseClientTemplate.I18n, templateConfig.I18n),
				AvailableLanguages: this.getValueOrDefault("AvailableLanguages", enrichedBaseClientTemplate.I18n, templateConfig.I18n),
			},
			AppExtras: this.getValueOrDefault("AppExtras", enrichedBaseClientTemplate, templateConfig),
			Routes: this.getRoutesValuesOrDefault(enrichedBaseClientTemplate.Routes, templateConfig.Routes),
		} as ClientTemplate;
	}

	private getValueOrDefault(key: string, base, specific) {
		return specific?.[key] ?? base?.[key];
	}

	private getRoutesValuesOrDefault(base: any, specific: any) {
		if (!specific) {
			return base;
		}

		const enrichedObject = {};
		for (const key of Object.keys(base)) {
			if (typeof base[key] === "object") {
				enrichedObject[key] = this.getRoutesValuesOrDefault(base[key], specific[key]);
			} else {
				enrichedObject[key] = specific[key] || base[key];
			}
		}

		return enrichedObject;
	}
}
