import { AccountProtectionChangeContact } from "#baseUrl/models/dto/outbound/accountProtectionChangeContact.model";
import { AccountProtectionChangeContactInput } from "#baseUrl/models/dto/outbound/accountProtectionChangeContactInput.model";
import { PerformAccountProtection } from "#baseUrl/models/dto/outbound/performAccountProtection.model";
import { ValidateCode } from "#baseUrl/models/dto/outbound/validateCode.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../state/app-state";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { mergeMap } from "rxjs/operators";

@Injectable()
export class AccountProtectionService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	accountProtection(phone: string, email: string, username: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtection}`;

		const body: PerformAccountProtection = {
			ConfirmLater: false,
			Phone: phone,
			Email: email,
		};

		if (!!username) {
			body.Username = username;
		}

		return this.doPost(url, body);
	}

	skipAccountProtection() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtection}`;

		const body: PerformAccountProtection = {
			ConfirmLater: true,
			Phone: null,
			Email: null,
			Username: null,
		};

		return this.doPost(url, body);
	}

	accountProtectionAllowSMS() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtectionSendSMSCode}`;

		return this.doPost(url, {});
	}

	accountProtectionCodeValidation(code: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtectionCodeValidation}`;

		const body: ValidateCode = { Value: code };

		return this.doPost(url, body);
	}

	accountProtectionChangeContact(type: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtectionChangeContact}`;

		const body: AccountProtectionChangeContact = {
			Type: type,
		};

		return this.doPost(url, body);
	}

	accountProtectionChangeContactInput(val: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtectionChangeContactInput}`;

		const body: AccountProtectionChangeContactInput = {
			Value: val,
		};
		return this.doPost(url, body);
	}

	rollback(jwt: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AccountProtectionChangeContactRollback}`;

		return this.doPost(url, jwt);
	}
}
