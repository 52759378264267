import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { DynamicComponentModule, NosIdCommonModule } from "@nosinovacao/nosid-mfe-common";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

const components = [];
const imports = [NosIdCommonModule, CommonModule, BrowserModule, ReactiveFormsModule, FormsModule, TranslateModule, RecaptchaFormsModule, RecaptchaModule];

@NgModule({
	declarations: components,
	imports: [...imports, DynamicComponentModule.forRoot({ imports: [SharedComponentsModule, RecaptchaFormsModule] })],
	exports: [...imports, ...components],
	providers: [],
})
export class SharedComponentsModule {}
