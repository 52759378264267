import { SharedComponentsModule } from "#baseUrl/components/shared/sharedComponents.module";
import { SignInRoutes } from "#baseUrl/models/configurations/routing/signInRoutes";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { CommonComponentsModuleOptions, DynamicComponentModule, DynamicFactory, LowerCaseUrlSerializerProvider } from "@nosinovacao/nosid-mfe-common";
import { RecaptchaSettings, RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { Observable } from "rxjs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/app.component";
import { GuardComponent } from "./components/guard.component";
import { NavBarComponent } from "./components/navbar/navbar.component";
import { PopupComponent } from "./components/popup/popup.component";
import { SnackBarComponent } from "./components/snackbar/snackbar.component";
import { ModuleName } from "./models/configurations/routing/module-name.enum";
import { ConfigurationService } from "./services/configuration.service";
import { FacadeService } from "./services/facade.service";
import { GlobalErrorHandler } from "./services/global-error-handling/global-error-handler";
import { JwtInterceptor } from "./services/interceptors/jwt.interceptor";
import { CanActivateGuardService } from "./services/navigation/can-activate-guard.service";
import { CanDeactivatePasswordGuardService } from "./services/navigation/can-deactivate-password.guard";
import { CanDeactivateUsernameGuardService } from "./services/navigation/can-deactivate-username.guard";
import { CanLoadGuard } from "./services/navigation/can-load-guard.service";
import { DirectSignupAuthorizeResolverGuard } from "./services/navigation/direct-signup-authorize.guard";
import { DirectSignupResolverGuard } from "./services/navigation/direct-signup-resolver.guard";
import { RootPathResolverGuard } from "./services/navigation/root-path-resolver.guard";
import { ServicesModule } from "./services/services.module";
import { TemplatesConfigurationService } from "./services/templatesConfiguration.service";
import { CurrentViewEffects } from "./state/current-view/current-view-effects";
import { currentViewReducer } from "./state/current-view/current-view-reducer";
import { LoggerEffects } from "./state/logger/logger-effects";
import { loggerReducer } from "./state/logger/logger-reducer";
import { NavigationEffects } from "./state/navigation/navigation-effects";
import { navigationReducer } from "./state/navigation/navigation-reducer";
import { popupReducer } from "./state/popup/popup-reducer";
import { SnackBarEffects } from "./state/snackbar/snackbar-effects";
import { snackBarReducer } from "./state/snackbar/snackbar-reducer";

// AoT requires an exported function for factories
export function DynamicHttpLoaderFactory(httpClient: HttpClient, templatesConfigService: TemplatesConfigurationService) {
	return new DynamicTranslateHttpLoader(httpClient, templatesConfigService);
}

export class DynamicTranslateHttpLoader implements TranslateLoader {
	constructor(private readonly http: HttpClient, private readonly templatesConfigService: TemplatesConfigurationService) {}

	getTranslation(lang: string): Observable<any> {
		const clientTemplateConfig = this.templatesConfigService.ClientTemplate;
		return this.http.get(`${clientTemplateConfig.I18n.Path}${lang}.json`);
	}
}

export function preloadAssets(templatesConfigService: TemplatesConfigurationService): () => Promise<void> {
	return (): Promise<void> =>
		new Promise((resolve) => {
			const clientTemplate = templatesConfigService.ClientTemplate;

			const templateFont = document.createElement("link");
			templateFont.rel = "stylesheet";
			templateFont.type = "text/css";
			templateFont.href = clientTemplate.FontDeclarationPath;
			templateFont.onload = () => resolve();

			window.document.getElementsByTagName("head")[0].appendChild(templateFont);
		});
}

@NgModule({
	declarations: [AppComponent, GuardComponent, PopupComponent, NavBarComponent, SnackBarComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,

		StoreModule.forRoot({
			CurrentView: currentViewReducer,
			Popup: popupReducer,
			SnackBar: snackBarReducer,
			Navigation: navigationReducer,
			Logger: loggerReducer,
		}),
		EffectsModule.forRoot([NavigationEffects, SnackBarEffects, CurrentViewEffects, LoggerEffects]),
		StoreDevtoolsModule.instrument(),
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: DynamicHttpLoaderFactory,
				deps: [HttpClient, TemplatesConfigurationService, Router],
			},
		}),
		SharedComponentsModule,
		ServicesModule,
		DynamicComponentModule.forRoot({
			imports: [SharedComponentsModule],
		}),
	],
	providers: [
		CanLoadGuard,
		CanActivateGuardService,
		CanDeactivateUsernameGuardService,
		CanDeactivatePasswordGuardService,
		RootPathResolverGuard,
		DirectSignupResolverGuard,
		DirectSignupAuthorizeResolverGuard,
		{
			provide: APP_INITIALIZER,
			useFactory: preloadAssets,
			deps: [TemplatesConfigurationService],
			multi: true,
		},
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{
			// processes all errors
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		{
			provide: CommonComponentsModuleOptions,
			useValue: {
				InputKey: SignInRoutes.NosId.Input,
				PasswordKey: SignInRoutes.NosId.PasswordInput,
				SelectKey: SignInRoutes.NosId.SelectInput,
				PhoneInput: SignInRoutes.NosId.PhoneInput,
			} as CommonComponentsModuleOptions,
		},
		LowerCaseUrlSerializerProvider,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useFactory: (config: ConfigurationService) => {
				return config.captchaSiteKey;
			},
			deps: [ConfigurationService],
		},
		{
			provide: RECAPTCHA_SETTINGS,
			useFactory: (config: ConfigurationService) => {
				return { siteKey: config.captchaChallengeSiteKey } as RecaptchaSettings;
			},
			deps: [ConfigurationService],
		},
	],

	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private readonly dynamicFactory: DynamicFactory, private readonly facadeService: FacadeService) {
		const urlParams = new URLSearchParams(window.location.search);

		//	SET LOGIN HINT ON SESSION STORAGE
		const loginHint = urlParams.get("login_hint");
		if (loginHint) {
			this.saveLoginHint(loginHint);
		}

		if (this.redirectToPortal()) {
			return;
		}

		//	SET CLIENT ID ON SESSION STORAGE
		const clientId = urlParams.get("client_id");
		if (clientId) {
			this.saveClientId(clientId);
		}

		if (!this.facadeService.templatesConfigurationService.ClientTemplate.hideCookiebot) {
			// ADD COOKIEBOT NOW, IF TEMPLATE ALLOWS COOKIEBOT
			this.facadeService.cookiebotService.addCookiebot();
		}

		if (window.location.pathname !== "/") {
			//	ENSURE THAT NOSID COMPONENTS ARE ALLWAYS LOADED WHEN NOT IN ROOT PATHNAME (AKA /)
			this.dynamicFactory.preloadModuleComponents(ModuleName.NosId);
		} else if (this.ensurePortalRedirectOrNotFound()) {
			facadeService.utilsService.redirect(facadeService.configurationService.NosIdPortalLink);
		}
	}

	private saveClientId(clientId: string) {
		const clientIdKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.ClientId}`;
		this.facadeService.sessionStorageService.setString(clientIdKey, clientId);
	}
	private saveLoginHint(loginHint: string) {
		const loginHintKey = `${this.facadeService.configurationService.LocalStorage.BaseToken}${this.facadeService.configurationService.LocalStorage.LoginHint}`;
		this.facadeService.sessionStorageService.setString(loginHintKey, loginHint);
	}

	private redirectToPortal(): boolean {
		const portalRedirectMap = this.facadeService.configurationService.NosIdPortalRedirects;
		if (!portalRedirectMap) {
			return false;
		}
		for (const key of Object.keys(portalRedirectMap)) {
			const { isValid, routeParams } = this.isValidRouteWithCurrentLocation(key);
			if (!isValid) {
				continue;
			}
			if (!this.ensurePortalRedirectOrNotFound()) {
				return true;
			}
			const newPath = Object.keys(routeParams).reduce((acc, curr) => acc.replace(curr, routeParams[curr]), portalRedirectMap[key]);

			const originalUrl = `${window.location.origin}${window.location.pathname}`;
			const newUrl = `${this.facadeService.configurationService.NosIdPortalLink}${newPath}`;
			const newUri = window.location.href.replace(originalUrl, newUrl);
			this.facadeService.utilsService.redirect(newUri);
			return true;
		}
		return false;
	}

	private ensurePortalRedirectOrNotFound(): boolean {
		const blackList = this.facadeService.configurationService.NosIdPortalRedirectsBlackList;
		const domainBlackListed = blackList.find(({ domain }) => window.location.host === domain);
		if (!domainBlackListed) {
			return true;
		}
		this.saveClientId(domainBlackListed.clientId);
		// we don't want go to portal, instead we redirect to the app not available page
		this.facadeService.utilsService.redirect(`${window.location.origin}/generic/forbidden`);
		return false;
	}

	private isValidRouteWithCurrentLocation(keyPath: string): { isValid: boolean; routeParams?: { [key: string]: any } } {
		if (keyPath.toLowerCase() === window.location.pathname.toLowerCase()) {
			return { isValid: true, routeParams: {} };
		}
		const splittedPath = keyPath.split("/");
		const splittedCurrentPath = window.location.pathname.split("/");
		if (splittedPath.length !== splittedCurrentPath.length) {
			return { isValid: false };
		}
		const routeParams = {};
		for (let i = 0; i < splittedPath.length; i++) {
			const subpath = splittedPath[i];
			const currentLocationSubpath = splittedCurrentPath[i];
			const isPathParam = subpath.indexOf("{") === 0 && subpath.indexOf("}") === subpath.length - 1;

			if (isPathParam) {
				routeParams[subpath] = currentLocationSubpath;
				continue;
			}
			if (subpath.toLowerCase() !== currentLocationSubpath.toLowerCase()) {
				return { isValid: false };
			}
		}

		return { isValid: true, routeParams };
	}
}
