export enum ErrorCodes {
	// SecurityCodes-API
	ExpiredSecurityCode = "ExpiredSecurityCode",
	InvalidSecurityCode = "InvalidSecurityCode",
	NotFoundSecurityCode = "NotFoundSecurityCode",
	InvalidSecurityCodeType = "InvalidSecurityCodeType",
	InvalidSecurityCodeChannel = "InvalidSecurityCodeChannel",
	MissingTargetId = "MissingTargetId",
	MissingEmailNotificationUri = "MissingEmailNotificationUri",
	MissingCode = "MissingCode",
	InvalidEmailAddress = "InvalidEmailAddress",
	// Logs-API
	InvalidSkipFilter = "InvalidSkipFilter",
	InvalidTopFilter = "InvalidTopFilter",
	InvalidUserIdFilter = "InvalidUserIdFilter",
	InvalidMobilePhoneFilter = "InvalidMobilePhoneFilter",
	//User-Manager-Api
	ExternalProviderAssociationNotFound = "ExternalProviderAssociationNotFound",
	UserRegistryUserTaken = "UserRegistryUserTaken",
	UserNotFound = "UserNotFound",
	UserAuthFailed = "UserAuthFailed",
	UserExternalProviderAssociationNotFound = "UserExternalProviderAssociationNotFound",
	UsernameNotFound = "UsernameNotFound",
	UserPending = "UserPending",
	UserLocked = "UserLocked",
	UserQuarantined = "UserQuarantined",
	UserInactive = "UserInactive",
	UserRegisteredOtherCustomer = "UserRegisteredOtherCustomer",
	UserCurrentPinFailed = "UserCurrentPinFailed",
	UserMissingCustomerBindData = "UserMissingCustomerBindData",
	UserAuthFailedMaxAttemptsReached = "UserAuthFailedMaxAttemptsReached",
	UserRegistryCustomerAdminExists = "UserRegistryCustomerAdminExists",
	PrimaryUserNotFound = "PrimaryUserNotFound",
	PrimaryCustomerAccountNotFound = "PrimaryCustomerAccountNotFound",
	SecondaryUserNotFound = "SecondaryUserNotFound",
	PersonNotFound = "PersonNotFound",
	AccountNotFound = "AccountNotFound",
	ParentInfoNotFound = "ParentInfoNotFound",
	InvalidUserInformation = "InvalidUserInformation",
	InvalidUsernameAliases = "InvalidUsernameAliases",
	DelegatedAccountNotFound = "DelegatedAccountNotFound",
	UserAlreadyAdminFromAnotherCustomerAccount = "UserAlreadyAdminFromAnotherCustomerAccount",
	ExternalProviderAssociationAlreadyRegistered = "ExternalProviderAssociationAlreadyRegistered",
	ContactAlreadyAssociatedToPerson = "ContactAlreadyAssociatedToPerson",
	UnableToAddContactsToPerson = "UnableToAddContactsToPerson",
	ImpossibleToCreatePerson = "ImpossibleToCreatePerson",
	UsernameAliasTypeAlreadyTaken = "UsernameAliasTypeAlreadyTaken",
	ParentMustBePrimary = "ParentMustBePrimary",
	//Account-Manager-Api
	PinTypeRequired = "PinTypeRequired",
	NewPinRequired = "NewPinRequired",
	HouseHoldCriteriaRequired = "HouseHoldCriteriaRequired",
	ProfileNicknameAlreadyInUse = "ProfileNicknameAlreadyInUse",
	//Applications-api
	ApplicationNotFound = "ApplicationNotFound",
	ApplicationClientNotFound = "ApplicationClientNotFound",
	ApplicationNotAuthorized = "ApplicationNotAuthorized",
	ApplicationNotAuthorizedByAdmin = "ApplicationNotAuthorizedByAdmin",
	ProfileTypeNotAuthorized = "ProfileTypeNotAuthorized",
	DuplicatedClientId = "DuplicatedClientId",
	//GeoLocation
	UserNotAtHome = "UserNotAtHome",
	NetworkNotAllowed = "NetworkNotAllowed",
	//HomeGateway
	HomeGatewayNotFound = "HomeGatewayNotFound",
	//Signup
	RequiredEmail = "RequiredEmail",
	RequiredPhoneNumber = "RequiredPhoneNumber",
	RequiredDisplayName = "RequiredDisplayName",
	NotConnectedNosMobileNetwork = "NotConnectedNosMobileNetwork",
	ContactsConfirmationDateNotValid = "ContactsConfirmationDateNotValid",
	DeviceNotRecognizedSecurityCodeSent = "DeviceNotRecognizedSecurityCodeSent",
	DeviceNotRecognizedCanNotSendSecurityCode = "DeviceNotRecognizedCanNotSendSecurityCode",
	LoginWithoutDeviceIdNotAllowed = "LoginWithoutDeviceIdNotAllowed",
	CannotSendSecurityCode = "CannotSendSecurityCode",
	InvalidUserPassword = "InvalidUserPassword",
	EmailAddressNotFound = "EmailAddressNotFound",
	PhoneNumberNotFound = "PhoneNumberNotFound",
	RequiredClientId = "RequiredClientId",
	RequiredUserAgent = "RequiredUserAgent",
	RequiredClientIp = "RequiredClientIp",
	//aa-api
	BadRequest = "BadRequest",
	UnauthorizedClient = "UnauthorizedClient",
	UnknownApplication = "UnknownApplication",
	UnknownError = "UnknownError",
	SecurityCodeInvalid = "SecurityCodeInvalid",
	InvalidNif = "InvalidNif",
	InvalidPhone = "InvalidPhone",
	InvalidPassport = "InvalidPassport",
	UsernameRequired = "UsernameRequired",
	UsernameAlreadyTaken = "UsernameAlreadyTaken",
	EmailAlreadyTaken = "EmailAlreadyTaken",
	AccessDenied = "AccessDenied",
	TermsAndConditionsNotAccepted = "TermsAndConditionsNotAccepted",
	UnauthorizedKid = "UnauthorizedKid",
	CanNotPostponeContactsConfirmation = "CanNotPostponeContactsConfirmation",
	InvalidUserState = "InvalidUserState",
	PhoneNotValidated = "PhoneNotValidated",
	UserAlreadyRegistered = "UserAlreadyRegistered",
	BackendTimeout = "BackendTimeout",
	BackendCircuitOpen = "BackendCircuitOpen",
	ProfileIdNotFound = "ProfileIdNotFound",
	DeviceCodeInvalid = "DeviceCodeInvalid",
	ExternalServiceError = "ExternalServiceError",
	NewPasswordMustBeDifferent = "NewPasswordMustBeDifferent",
	// portal-api
	InvalidIdentificationType = "InvalidIdentificationType",
	InvalidUpdateProfileRequest = "InvalidUpdateProfileRequest",
	RequestedContactNotFound = "RequestedContactNotFound",
	SelectedSaNotFound = "SelectedSaNotFound",
	OnlyPrimaryUserCanViewOrSetPins = "OnlyPrimaryUserCanViewOrSetPins",
	OnlyPrimaryUsersAllowed = "OnlyPrimaryUsersAllowed",
	BaseUserMustBeSecondaryOfCurrentUser = "BaseUserMustBeSecondaryOfCurrentUser",
	UserIsAlreadyParent = "UserIsAlreadyParent",
	UserAlreadyExistsWithInvalidState = "UserAlreadyExistsWithInvalidState",
	OnlySecondaryUsersCanRequestAppAccess = "OnlySecondaryUsersCanRequestAppAccess",
	SecondaryUserHasNoParentUserId = "SecondaryUserHasNoParentUserId",
	PrimaryUserHasNoAccessToApplication = "PrimaryUserHasNoAccessToApplication",
	CanNotGivePermissionToAdminOnlyApp = "CanNotGivePermissionToAdminOnlyApp",
	InvalidPrivacyPolicies = "InvalidPrivacyPolicies",
	ApiExceptionUnknownError = "ApiExceptionUnknownError",
	SecondaryUserIdIsRequiredAsPrimaryUser = "SecondaryUserIdIsRequiredAsPrimaryUser",
	UserHasNoAccessToSa = "UserHasNoAccessToSa",
	ContactsRollbackIsNotValidAnymore = "ContactsRollbackIsNotValidAnymore",

	InvalidTokenError = "InvalidTokenError",
	ForbidenTokenError = "ForbidenTokenError",
	Challenge = "Challenge",
	NonLegitimateUser = "NonLegitimateUser",
}
