import { AAMicroserviceConfiguration } from "#baseUrl/models/configurations/environment/apiConfigurations.interface";
import { ErrorCodes } from "#baseUrl/models/dto/inbound/error/errors.enum";
import { AccountCredentials } from "#baseUrl/models/dto/inbound/success/accountCredentials.model";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { Action } from "#baseUrl/models/navigation/action.enum";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded, setViewDataLoading } from "#baseUrl/state/current-view/current-view-actions";
import { navigateTo, navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { showSnackBar } from "#baseUrl/state/snackbar/snackbar-actions";
import { SnackBarState, SnackBarType } from "#baseUrl/state/snackbar/snackbar-state";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mergeMap, tap } from "rxjs/operators";
import { ConfigurationService } from "../configuration.service";
import { PhoneService } from "@nosinovacao/nosid-mfe-common";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class AAService {
	protected readonly CAPTCHA_HTTP_HEADER = "X-Core-Captcha";
	protected readonly CAPTCHA_CHALLENGE_HTTP_HEADER = "X-Core-Captcha-Challenge";
	protected microServiceConfig: AAMicroserviceConfiguration;

	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		protected readonly recaptchaService: ReCaptchaV3Service
	) {
		this.microServiceConfig = this.configurationService.APIConfiguration.AA;
	}

	next() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.NextEndpoint}`;

		return this.doGet(url);
	}

	requestApplicationAccess() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AuthorizeRequestAppPermission}`;

		return this.doPost(url, {});
	}

	resendCode() {
		//  THIS METHOD IS NOT USING DOPOST METHOD ON PURPOSE.
		// 	THE RESPONSE MUST BE HANDLED DIFERENTLY IN ORDER TO DISPATCH POPUPS AND/OR SNACKBAR MESSAGES
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.CodeResend}`;

		this.store.dispatch(setViewDataLoading());
		return this.httpService.post(url, {}).pipe(
			tap((res: ResponseAction<any>) => {
				this.store.dispatch(setViewDataLoaded());

				if (!res.Error) {
					this.store.dispatch(
						showSnackBar({
							Type: SnackBarType.Info,
							Message: "Common.SnackBar.ResendCode.Success",
						} as SnackBarState)
					);
				} else {
					this.store.dispatch(navigateToError(res));
				}
			})
		);
	}
	protected doGet(url: string, headers?: any) {
		this.store.dispatch(setViewDataLoading());

		return this.httpService.get(url, { headers }).pipe(tap(this.handleResponse.bind(this)));
	}

	protected doPost<T>(url: string, body: T, headers?: any) {
		this.store.dispatch(setViewDataLoading());

		return this.httpService.post(url, body, { headers }).pipe(tap(this.handleResponse.bind(this)));
	}

	protected doPostWithCountryCodeConfirmationOnError<T>(
		url: string,
		body: T,
		username: string,
		phoneService: PhoneService,
		redirectPhoneCodeAction: Action
	): void {
		this.store.dispatch(setViewDataLoading());

		this.httpService.post(url, body).subscribe((res: ResponseAction<any>) => {
			this.store.dispatch(setViewDataLoaded());

			if (!res.Error) {
				this.store.dispatch(navigateTo(res));
			} else {
				const code = res.Error.Params && res.Error.Params.find((x) => x.Code === ErrorCodes.UserNotFound);
				/*
					SHOULD SHOW PHONE COUNTRY CONFIRMATION IF ALL OF THE FOLLOWING CONDITIONS ARE MET:
					-	RESPONSE FROM SERVER IS USERNOTFOUND;
					-	INPUT USERNAME IS ALL NUMBERS;
					-	IS NOT A DEFAULT COUNTRY NUMBER;
				*/
				if (code && !isNaN(parseInt(username, 10)) && !phoneService.isValid(username, phoneService.getDefaultCountry())) {
					this.store.dispatch(
						navigateTo({
							Action: redirectPhoneCodeAction,
							Error: null,
							Token: res.Token,
							Data: {
								Phone: username,
							} as AccountCredentials,
						} as ResponseAction<AccountCredentials>)
					);
				} else {
					this.store.dispatch(navigateToError(res));
				}
			}
		});
	}

	protected handleResponse(res: ResponseAction<any>) {
		this.store.dispatch(setViewDataLoaded());

		if (!!res.Error) {
			this.store.dispatch(navigateToError(res));
		} else {
			this.store.dispatch(navigateTo(res));
		}
	}
}
