import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { UsernameComponent } from "../../features/authentication/username/username.component";

@Injectable()
export class CanDeactivateUsernameGuardService implements CanDeactivate<UsernameComponent> {
	canDeactivate(
		component: UsernameComponent,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): boolean {
		return nextState && nextState.url.indexOf("authorize") < 0;
	}
}
