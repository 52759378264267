import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { GuardComponent } from "./components/guard.component";
import { ModuleName } from "./models/configurations/routing/module-name.enum";
import { CanActivateGuardService } from "./services/navigation/can-activate-guard.service";
import { CanLoadGuard } from "./services/navigation/can-load-guard.service";
import { DirectSignupResolverGuard } from "./services/navigation/direct-signup-resolver.guard";

const routes: Routes = [
	{
		path: "logout",
		canActivate: [CanActivateGuardService],
		component: GuardComponent,
	},
	{
		path: "authentication",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/authentication/authentication.module").then((m) => m.AuthenticationModule),
		data: { ModuleName: ModuleName.Authentication },
	},
	{
		path: "mandatory-email",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/mandatory-email/mandatory-email.module").then((m) => m.EmailValidationModule),
		data: { ModuleName: ModuleName.MandatoryEmail },
	},
	{
		path: "signup",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/signup/signup.module").then((m) => m.SignUpModule),
		data: { ModuleName: ModuleName.SignUp },
	},
	{
		path: "activate",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/device-code/device-code.module").then((m) => m.DeviceCodeModule),
		data: { ModuleName: ModuleName.DeviceCode },
	},
	{
		path: "social",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/social-networks/social-networks.module").then((m) => m.SocialNetworksModule),
		data: { ModuleName: ModuleName.SocialNetworks },
	},
	{
		path: "account-association",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/account-association/account-association.module").then((m) => m.AccountAssociationModule),
		data: { ModuleName: ModuleName.AccountAssociation },
	},
	{
		path: "account-selection",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/account-selection/account-selection.module").then((m) => m.AccountSelectionModule),
		data: { ModuleName: ModuleName.AccountSelection },
	},
	{
		path: "terms-consents",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/terms-consents/terms-consents.module").then((m) => m.TermsConsentsModule),
		data: { ModuleName: ModuleName.TermsConsents },
	},
	{
		path: "account-protection",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/account-protection/account-protection.module").then((m) => m.AccountProtectionModule),
		data: { ModuleName: ModuleName.AccountProtection },
	},
	{
		path: "password-recovery",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/password-recovery/password-recovery.module").then((m) => m.PasswordRecoveryModule),
		data: { ModuleName: ModuleName.PasswordRecovery },
	},
	{
		path: "generic",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/generic/generic.module").then((m) => m.GenericModule),
		data: { ModuleName: ModuleName.Generic },
	},
	{
		path: "two-factor-authentication",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/two-factor-authentication/two-factor-authentication.module").then((m) => m.TwoFactorAuthenticationModule),
		data: { ModuleName: ModuleName.TwoFactorAuthentication },
	},
	{
		path: "profiles",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/profile-selection/profiles-selection.module").then((m) => m.ProfilesSelectionModule),
		data: { ModuleName: ModuleName.ProfileSelection },
	},
	{
		path: "o/:id/Signup",
		pathMatch: "full",
		canActivate: [DirectSignupResolverGuard],
		component: GuardComponent,
	},
	{
		path: "",
		canLoad: [CanLoadGuard],
		loadChildren: () => import("./features/entry/entry.module").then((m) => m.EntryModule),
		data: { ModuleName: ModuleName.EntryRoutes },
	},
];

@NgModule({
	imports: [BrowserModule, BrowserAnimationsModule, RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
