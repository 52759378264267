import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ConfigurationService } from "../configuration.service";
import { UtilsService } from "../utils.service";

@Injectable()
export class RootPathResolverGuard implements CanActivate {
	constructor(private readonly utilsService: UtilsService, private readonly configurationService: ConfigurationService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		this.utilsService.redirect(this.configurationService.NosIdPortalLink);
		return false;
	}
}
