import { ExternalLoginProviders } from "#baseUrl/models/configurations/external-login/login-providers.enum";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded } from "#baseUrl/state/current-view/current-view-actions";
import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigurationService } from "../configuration.service";
import { ExternalLoginService } from "./external-login.service";

type GoogleAuth = gapi.auth2.GoogleAuth;
type GoogleUser = gapi.auth2.GoogleUser;

@Injectable()
export class GoogleLoginService extends ExternalLoginService {
	private readonly oAuthClient: Promise<GoogleAuth>;

	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		protected readonly zone: NgZone
	) {
		super(httpService, configurationService, store, zone);
	}

	login(): void {
		this.oAuthClient
			.then((client) => {
				if (!!client) {
					if (!client.isSignedIn.get()) {
						client
							.signIn()
							.then((user: GoogleUser) => {
								this.finishAuthentication(ExternalLoginProviders.Google, user.getAuthResponse().id_token);
								this.logout();
							})
							.catch((e) => {
								this.store.dispatch(setViewDataLoaded());
							});
					} else {
						this.finishAuthentication(ExternalLoginProviders.Google, client.currentUser.get().getAuthResponse().id_token);
						this.logout();
					}
				}
			})
			.catch((e) => {
				this.store.dispatch(setViewDataLoaded());
			});
	}

	logout(): void {
		this.oAuthClient.then((client) => {
			if (client && !!client.isSignedIn.get()) {
				client.signOut();
			}
		});
	}

	initProvider(): Promise<GoogleAuth> {
		return new Promise((resolve) => {
			//	Async load google api script
			const node = document.createElement("script");
			node.src = this.configurationService.ExternalLoginProviders.Google.API;
			node.async = true;

			// 	Retrieve the singleton for the GoogleAuth library on load script
			node.onload = () => {
				gapi.load("auth2", () => {
					resolve(
						gapi.auth2.init({
							client_id: this.configurationService.ExternalLoginProviders.Google.ClientId,
						})
					);
				});
			};

			node.onerror = () => {
				resolve(null);
			};

			//	Inject script into head element
			document.getElementsByTagName("head")[0].appendChild(node);
		});
	}
}
