import { Avatar } from "#baseUrl/models/dto/inbound/success/avatar.model";
import { CreateProfile } from "#baseUrl/models/dto/outbound/createProfile.model";
import { SelectProfile } from "#baseUrl/models/dto/outbound/selectProfile.model";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AAService } from "./aa.service";

@Injectable()
export class KidsProfileService extends AAService {
	selectProfile(id: string): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.SelectProfile}`;
		const body: SelectProfile = {Id: id};
		return this.doPost(url, body);
	}

	goToCreateProfile(): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.CreateProfile}`;
		return this.doGet(url);
	}

	createProfile(name: string, avatar: Avatar, ageRating: string): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.CreateProfile}`;
		const body: CreateProfile = {
			NickName: name,
			DisplayName: name,
			ContentRatingLimit: ageRating,
			Avatar: {
				Id: avatar.Id,
				ImageId: avatar.ImageId,
			},
		};
		return this.doPost(url, body);
	}
}
