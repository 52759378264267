import { LoggerProvider } from "./logger.interface";
import { ConfigurationService } from "#baseUrl/services/configuration.service";
import { TemplatesConfigurationService } from "#baseUrl/services/templatesConfiguration.service";

export class ConsoleProvider extends LoggerProvider {
	constructor(configurationService: ConfigurationService, templatesConfiguration: TemplatesConfigurationService) {
		super(configurationService, templatesConfiguration);
	}

	logEvent(message: string, data?: { [key: string]: any }): void {
		!!data ? console.log(message, data) : console.log(message);
	}
}
