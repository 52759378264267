import { createFeatureSelector, createSelector } from "@ngrx/store";

export interface LoggerState {
	Step: string;
	Screen: string;
}

export const selectLoggerFeature = createFeatureSelector("Logger");

export const selectLogger = createSelector(selectLoggerFeature, (state: LoggerState) => state);
