import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AuthenticationService } from "../aa";
import { ConfigurationService } from "../configuration.service";

@Injectable()
export class DirectSignupResolverGuard implements CanActivate {
	constructor(
		private readonly configurationService: ConfigurationService,
		private readonly aaService: AuthenticationService,
		private readonly store: Store
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		const callbackUrl = route.queryParamMap.get("callback_url");
		let url = this.configurationService.NosIdPortalLink;
		if (callbackUrl) {
			try {
				const decodedCallbackUrl = new URL(decodeURIComponent(atob(callbackUrl)));
				decodedCallbackUrl.searchParams.append("sign_up", "true");
				url = decodedCallbackUrl.toString();
			} catch {
			}
		}

		return this.aaService.logout(url);
	}
}
