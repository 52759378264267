import { AAMicroserviceConfiguration } from "#baseUrl/models/configurations/environment/apiConfigurations.interface";
import { ErrorCodes } from "#baseUrl/models/dto/inbound/error/errors.enum";
import { CountryCodeValidation } from "#baseUrl/models/dto/inbound/success/countryCodeValidation.model";
import { ValidateUsername } from "#baseUrl/models/dto/outbound/validate-username";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { Action } from "#baseUrl/models/navigation/action.enum";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded, setViewDataLoading, updateViewData } from "#baseUrl/state/current-view/current-view-actions";
import { navigateTo, navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { mergeMap, tap } from "rxjs/operators";
import { PhoneService } from "@nosinovacao/nosid-mfe-common";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class GenericService extends AAService {
	protected microServiceConfig: AAMicroserviceConfiguration;

	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		private readonly phoneService: PhoneService,
		recaptcha: ReCaptchaV3Service
	) {
		super(httpService, configurationService, store, recaptcha);
	}

	validateUsername(specificUsernamePath: string, username: string, honeyPot: string, tokenActionName?: string, tokenChallenge?: string) {
		const url = `${this.configurationService.APIConfiguration.AA.BaseUrl}${specificUsernamePath}`;

		const body = { Username: username, Email: honeyPot ? honeyPot : undefined } as ValidateUsername;

		this.store.dispatch(setViewDataLoading());
		const req = (headers?: { [k: string]: any }) =>
			this.httpService.post(url, body, { headers }).pipe(
				tap((res: ResponseAction<any>) => {
					this.store.dispatch(setViewDataLoaded());

					if (!res.Error) {
						this.store.dispatch(navigateTo(res));
					} else {
						const code = res.Error.Params && res.Error.Params.find((x) => x.Code === ErrorCodes.UserNotFound);
						/*
						SHOULD SHOW PHONE COUNTRY CONFIRMATION IF ALL OF THE FOLLOWING CONDITIONS ARE MET:
						-	RESPONSE FROM SERVER IS USERNOTFOUND;
						-	INPUT USERNAME IS ALL NUMBERS;
						-	IS NOT A DEFAULT COUNTRY NUMBER;
					*/
						if (code && !isNaN(parseInt(username, 10)) && !this.phoneService.isValid(username, this.phoneService.getDefaultCountry())) {
							this.store.dispatch(
								navigateTo({
									Action: Action.GenericCountryCodeConfirmation,
									Error: null,
									Token: res.Token,
									Data: {
										UsernamePath: specificUsernamePath,
										Phone: username,
									} as CountryCodeValidation,
								} as ResponseAction<CountryCodeValidation>)
							);
						} else {
							this.store.dispatch(navigateToError(res));
						}
					}
				})
			);
		if (!tokenActionName) {
			return req();
		}

		if (tokenChallenge) {
			return req({ [this.CAPTCHA_HTTP_HEADER]: tokenChallenge, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}

		return this.recaptchaService.execute(tokenActionName).pipe(
			mergeMap((token) => {
				return req({ [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	confirmCountryCode(specificUsernamePath: string, username: string, tokenChallenge?: string) {
		//	THIS FLOW IS JUST CLIENT SIDE... COUNTRY CODE CONFIRMATION VIEW IS CONSIDERED THE SAME VIEW AS USERNAME VIEW
		//	WE NEED TO HANDLE THE RESPONSE AS IF WE WHERE IN THE USERNAME VIEW
		const url = `${this.microServiceConfig.BaseUrl}${specificUsernamePath}`;

		const body: ValidateUsername = { Username: username };

		this.store.dispatch(setViewDataLoading());

		if (tokenChallenge) {
			return this.requestConfirmCountryCode(url, body, { [this.CAPTCHA_HTTP_HEADER]: tokenChallenge, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}

		return this.recaptchaService.execute("ConfirmCountryCode").pipe(
			mergeMap((token) => {
				return this.requestConfirmCountryCode(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	private requestConfirmCountryCode(url: string, body: any, headers: any) {
		return this.httpService.post(url, body, { headers }).pipe(
			tap((res: ResponseAction<any>) => {
				this.store.dispatch(setViewDataLoaded());
				if (!res.Error) {
					this.store.dispatch(navigateTo(res));
				} else {
					//	SPECIAL CASE TO HANDLE ACTIONS FOR COUNTRY CODE VALIDATIONS
					if (
						res.Action === Action.Unauthenticated ||
						res.Action === Action.PasswordRecovery ||
						res.Action === Action.SocialNetworkAssociationUsernameInput
					) {
						this.store.dispatch(updateViewData(res));
					} else {
						//	NAVIGATE ELSWHERE
						this.store.dispatch(navigateToError(res));
					}
				}
			})
		);
	}
}
