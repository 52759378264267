import { AnalyticsWebInterface, NativeAnalytics } from "./native-analytics";

export class AndroidAnalytics extends NativeAnalytics<AnalyticsWebInterface> {
	constructor() {
		super();
		if (!this.analyticsInterface) {
			throw Error("Android Analytics Web interface not found");
		}
	}

	get analyticsInterface(): AnalyticsWebInterface {
		return window["AnalyticsWebInterface"] as AnalyticsWebInterface;
	}

	protected setSingleUserProperty(name: any, value: any) {
		this.analyticsInterface.setUserProperty(name, value);
	}

	protected logEventInternal(name: string, value?: { [key: string]: any }) {
		this.analyticsInterface.logEvent(name, value ? JSON.stringify(value) : null);
	}
}
