import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorageService } from "@nosinovacao/nosid-mfe-common";
import { ConfigurationService } from "../configuration.service";

@Injectable()
export class ChangeAccountService {
	constructor(
		private readonly storageService: SessionStorageService,
		private readonly configurationService: ConfigurationService,
		private readonly router: Router
	) {
	}

	changeAccount(): void {
		const authParamsKey = `${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.AuthorizeParams}`;
		const authParams = this.storageService.getObject(authParamsKey) || {};
		const hasAuthorizeParams = Object.keys(authParams).length > 0;
		const redirectUri = new URL(hasAuthorizeParams ? `${window.location.origin}/authorize` : window.location.origin);

		Object.keys(authParams).forEach((key) => {
			redirectUri.searchParams.append(key, authParams[key]);
		});

		this.router.navigate(["/logout"], {
			queryParams: {
				redirect_uri: redirectUri.toString(),
			},
		});
	}
}
