import { EnterpriseContextService } from "#baseUrl/services/enterprise-context.service";
import { NgModule } from "@angular/core";
import { DynamicFactory, NosidCommonServicesModuleModule } from "@nosinovacao/nosid-mfe-common";
import { RecaptchaV3Module } from "ng-recaptcha";
import {
	AccountAssociationService,
	AccountProtectionService,
	AccountSelectionService,
	AuthenticationService,
	GenericService,
	MandatoryEmailService,
	PasswordRecoveryService,
	SignUpService,
	SocialNetworkService,
	TermsAndConsentsService,
	TwoFactorAuthenticationService,
} from "./aa";
import { AAService } from "./aa/aa.service";
import { DeviceCodeService } from "./aa/deviceCode.service";
import { KidsProfileService } from "./aa/kidsProfile.service";
import { ConfigurationService } from "./configuration.service";
import { CookiebotService } from "./cookiebot/cookiebot.service";
import { DeviceIdService } from "./deviceId.service";
import { AppleLoginService } from "./external-login/apple-login.service";
import { FacebookLoginService } from "./external-login/facebook-login.service";
import { GoogleLoginService } from "./external-login/google-login.service";
import { FacadeService } from "./facade.service";
import { LoggerService } from "./logging/logger.service";
import { MageService } from "./mage.service";
import { ChangeAccountService } from "./navigation/changeAccount.service";
import { NavigationStateService } from "./navigation/navigation-state.service";
import { TemplatesConfigurationService } from "./templatesConfiguration.service";
import { UtilsService } from "./utils.service";

function routeMapFactory(templatesConfigurationService: TemplatesConfigurationService) {
	return () => templatesConfigurationService.RouteMap;
}

@NgModule({
	imports: [NosidCommonServicesModuleModule.forRoot([TemplatesConfigurationService], routeMapFactory), RecaptchaV3Module],
	providers: [
		ConfigurationService,
		TemplatesConfigurationService,
		LoggerService,
		UtilsService,
		GoogleLoginService,
		FacebookLoginService,
		AppleLoginService,
		AAService,
		AuthenticationService,
		MandatoryEmailService,
		SignUpService,
		PasswordRecoveryService,
		MageService,
		ChangeAccountService,
		AccountProtectionService,
		TermsAndConsentsService,
		AccountAssociationService,
		AccountSelectionService,
		SocialNetworkService,
		TwoFactorAuthenticationService,
		GenericService,
		NavigationStateService,
		DynamicFactory,
		KidsProfileService,
		DeviceCodeService,
		DeviceIdService,
		EnterpriseContextService,
		FacadeService,
		CookiebotService,
	],
})
export class ServicesModule {}
