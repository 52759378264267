import { ActionType } from "#baseUrl/models/analytics/action-type.enum";
import { EventParameters } from "#baseUrl/models/analytics/event-parameters";
import { ExceptionType } from "#baseUrl/models/analytics/exception-type.enum";
import { ModuleName } from "#baseUrl/models/configurations/routing/module-name.enum";
import { NOSIDError } from "#baseUrl/models/dto/inbound/error/nosid-error";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { NavigationDirectionEnum } from "#baseUrl/models/navigation/navigation-direction.enum";
import { FacadeService } from "#baseUrl/services/facade.service";
import { AppState } from "#baseUrl/state/app-state";
import { sendExceptionEvent, sendLeaveViewEvent, sendScreenActionEvent, sendScreenNavigationEvent, setCurrentPage } from "#baseUrl/state/logger/logger-actions";
import { selectNavigationDirection } from "#baseUrl/state/navigation/navigation-state";
import { Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Dynamic } from "@nosinovacao/nosid-mfe-common";

@Directive()
export abstract class DynamicComponent extends Dynamic implements OnInit, OnDestroy {

	@ViewChild("nosidAnimationElementRef")
	protected nosidAnimationElementRef;

	constructor(protected readonly store: Store<AppState>, routeKey: string, readonly facadeService: FacadeService) {
		super(routeKey, facadeService.DynamicFactory);
	}

	async ngOnInit(): Promise<any> {
		await super.ngOnInit();

		this.store
			.select(selectNavigationDirection)
			.subscribe((direction: NavigationDirectionEnum) => {
				if (
					this.nosidAnimationElementRef &&
					this.nosidAnimationElementRef.nativeElement &&
					this.nosidAnimationElementRef.nativeElement.classList.contains("nosid-animator")
				) {
					this.nosidAnimationElementRef.nativeElement.classList.add("nosid-enter-animation");

					this.nosidAnimationElementRef.nativeElement.classList.add(`nosid-enter-animation-${direction.toLowerCase()}`);
				}
			})
			.unsubscribe();

		if (this.routeKey.split("|")[0] !== ModuleName.NosId) {
			this.store.dispatch(setCurrentPage({ routerKey: this.routeKey }));
		}

		return true;
	}

	ngOnDestroy(): void {
		if (this.routeKey.split("|")[0] !== ModuleName.NosId) {
			this.store.dispatch(sendLeaveViewEvent());
		}
	}

	createExceptionMessageInputError(errors: any): string[] {
		const exceptionMessage = [];
		Object.values(errors).forEach((error: string) => {
			if (error) {
				exceptionMessage.push(error.split(".").pop());
			}
		});
		return exceptionMessage;
	}

	createExceptionMessageServerError(errors: NOSIDError[]) {
		return errors.map((error: NOSIDError) => {
			return error.Code;
		});
	}

	createLogEvent(res: ResponseAction<any>, actionType: ActionType, option: string = null) {
		if (!!res.Error) {
			this.store.dispatch(
				sendExceptionEvent({
					eventParameters: {
						ActionType: actionType,
						Option: option,
						ExceptionType: ExceptionType.Server,
						ExceptionMessage: res.Error.Code != null ? [res.Error.Code] : this.createExceptionMessageServerError(res.Error.Params),
					} as EventParameters,
				})
			);
		} else {
			switch (actionType) {
				case ActionType.ChangeContact:
				case ActionType.Signup:
				case ActionType.PasswordRecovery:
				case ActionType.Cancel:
				case ActionType.SocialNetworks:
					this.store.dispatch(sendScreenNavigationEvent({ eventParameters: { ActionType: actionType, Option: option } as EventParameters }));
					break;
				default:
					this.store.dispatch(sendScreenActionEvent({ eventParameters: { ActionType: actionType, Option: option } as EventParameters }));
			}
		}
	}

	parseFormErrors(form: FormGroup, errorTranslateKey: string): any {
		return Object.keys(form.value).reduce((acc: any, key: string) => {
			acc[key] = this.getInputError(form, errorTranslateKey, key);
			return acc;
		}, {});
	}

	changeAccount(): void {
		this.store.dispatch(sendScreenActionEvent({ eventParameters: { ActionType: ActionType.ChangeAccount } as EventParameters }));
		this.facadeService.changeAccountService.changeAccount();
	}

	baseOnSubmit(form: FormGroup, errorTranslateKey: string, onSuccess: (form: FormGroup) => void, getOptions: () => string = () => undefined): void {
		if (form.pristine) {
			form.updateValueAndValidity();
		}
		if (form.invalid) {
			this.data.errors = this.parseFormErrors(form, errorTranslateKey);
			this.store.dispatch(
				sendExceptionEvent({
					eventParameters: {
						ActionType: ActionType.Submit,
						ExceptionType: ExceptionType.Input,
						ExceptionMessage: this.createExceptionMessageInputError(this.data.errors),
						Option: getOptions(),
					} as EventParameters,
				})
			);
			return;
		}
		onSuccess(form);
	}

	private getInputError(form: FormGroup, errorTranslateKey: string, key: string): string | boolean {
		const errors = form.get(key).errors;
		if (!errors) {
			return false;
		}
		if (errors.required) {
			return `${errorTranslateKey}.${key}Required`;
		}

		if (errors.EmailFormatInvalid) {
			return `${errorTranslateKey}.${key}EmailFormatInvalid`;
		}

		if (errors.OnlyNumbersNotAllowed) {
			return `${errorTranslateKey}.${key}OnlyNumbersNotAllowed`;
		}

		return `${errorTranslateKey}.${key}Invalid`;
	}
}
