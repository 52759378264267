import { ValidateCode } from "#baseUrl/models/dto/outbound/validateCode.model";
import { AppState } from "#baseUrl/state/app-state";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { mergeMap } from "rxjs/operators";

@Injectable()
export class TwoFactorAuthenticationService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	twoFactorAuthenticationCodeValidation(code: string, tokenChallenge: string, promote: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${
			promote ? this.microServiceConfig.TwoFactorAuthPromoteCodeValidation : this.microServiceConfig.TwoFactorAuthCodeValidation
		}`;

		const body: ValidateCode = { Value: code };

		if (tokenChallenge) {
			return this.doPost(url, body, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("TwoFactorAuthCodeValidation").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	activateNow(tokenChallenge: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.TwoFactorAuthPromoteCodeInput}`;

		if (tokenChallenge) {
			return this.doPost(
				url,
				{},
				{
					[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
					[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
				}
			);
		}

		return this.recaptchaService.execute("TwoFactorAuthCodeInput").pipe(
			mergeMap((token) => {
				return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	initSendCodeByEmail(tokenChallenge: string, promote: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${
			promote ? this.microServiceConfig.TwoFactorAuthPromoteByEmail : this.microServiceConfig.TwoFactorAuthByEmail
		}`;

		if (tokenChallenge) {
			return this.doPost(
				url,
				{},
				{
					[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
					[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
				}
			);
		}

		return this.recaptchaService.execute("TwoFactorAuthByEmail").pipe(
			mergeMap((token) => {
				return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}
}
