import { ExternalLoginProvidersConfiguration } from "#baseUrl/models/configurations/external-login/external-login-providers.interface";
import { LoggingConfiguration } from "#baseUrl/models/configurations/logging/logging-providers.interface";
import { Inject, Injectable } from "@angular/core";
import { APIConfiguration } from "../models/configurations/environment/apiConfigurations.interface";
import { EnvironmentConfigurationEnum } from "../models/configurations/environment/environmentConfiguration.enum";
import { LocalStorageConfiguration } from "../models/configurations/environment/localStorageConfiguration.interface";
import { SignInConfiguration } from "../models/configurations/environment/signInConfiguration.interface";
import { BlackListDomain } from "#baseUrl/models/configurations/environment/blackListDomain";
import { CookiebotConfiguration, SessionStorageService } from "@nosinovacao/nosid-mfe-common";

@Injectable()
export class ConfigurationService {
	protected readonly config: SignInConfiguration;

	constructor(@Inject("config") config: SignInConfiguration, private readonly sessionStorageService: SessionStorageService) {
		this.config = config;
	}

	get Version(): string {
		return this.config.Version;
	}

	get Environment(): EnvironmentConfigurationEnum {
		return this.config.Environment;
	}

	get APIConfiguration(): APIConfiguration {
		return this.config.APIConfiguration;
	}

	get LocalStorage(): LocalStorageConfiguration {
		return this.config.LocalStorage;
	}

	get LoggingConfiguration(): LoggingConfiguration {
		return this.config.LoggingConfiguration;
	}

	get ExternalLoginProviders(): ExternalLoginProvidersConfiguration {
		return this.config.ExternalLoginProviders || ({} as ExternalLoginProvidersConfiguration);
	}

	get NosIdPortalLink(): string {
		return this.config.NosIdPortalLink;
	}

	get NosIdPortalRedirects(): { [key: string]: string } {
		return this.config.PortalRedirects;
	}

	get NosidTermsAndConditionsLink(): string {
		return this.config.TermsAndConditionsLink;
	}

	get ClientId(): string {
		const authParamsKey = `${this.config.LocalStorage.BaseToken}${this.config.LocalStorage.ClientId}`;
		const clientId = this.sessionStorageService.getString(authParamsKey);
		return clientId || this.DefaultClientId;
	}

	get DefaultClientId(): string {
		return this.config.PortalClientId;
	}

	get SingleStepLoginValidProxyVersions() {
		return this.config.APIConfiguration.SingleStepLoginValidProxyVersions ?? [];
	}

	get NosIdPortalRedirectsBlackList(): BlackListDomain[] {
		return this.config.PortalRedirectsBlackList ?? [];
	}

	get CookiebotConfig(): CookiebotConfiguration {
		return this.config.CookieBotConfig;
	}

	get captchaSiteKey(): string {
		return this.config.RecaptchaSiteKey;
	}
	get captchaChallengeSiteKey(): string {
		return this.config.RecaptchaChallengeSiteKey;
	}

	get TwoFactorAuthFallbackCount(): number {
		return this.config.TwoFactorAuthFallbackCount;
	}
}
