import { NativeAnalytics, WebkitMessage, WebkitMessageHandlers } from "./native-analytics";

export class IosAnalytics extends NativeAnalytics<WebkitMessageHandlers> {
	constructor() {
		super();
		if (!this.analyticsInterface) {
			throw Error("IOS Webkit Message Handler not found");
		}
	}

	get analyticsInterface(): WebkitMessageHandlers {
		if (window["webkit"] && window["webkit"].messageHandlers && window["webkit"].messageHandlers.firebase) {
			return window["webkit"].messageHandlers.firebase as WebkitMessageHandlers;
		}
		return null;
	}

	protected setSingleUserProperty(name: any, value: any) {
		this.analyticsInterface.postMessage({
			command: "setUserProperty",
			name,
			value,
		} as WebkitMessage);
	}

	protected logEventInternal(name: string, value?: { [key: string]: any }) {
		this.analyticsInterface.postMessage({
			command: "logEvent",
			name,
			parameters: value ? value : undefined,
		});
	}
}
