import { FacadeService } from "#baseUrl/services/facade.service";
import { DynamicComponent } from "#baseUrl/shared/dynamics/dynamic.component";
import { AppState } from "#baseUrl/state/app-state";
import { hidePopup } from "#baseUrl/state/popup/popup-actions";
import { PopupAction, PopupButtonType, PopupPosition, PopupState, selectPopup } from "#baseUrl/state/popup/popup-state";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { SignInRoutes } from "../../models/configurations/routing/signInRoutes";

@Component({
	selector: "nosid-popup",
	template: `
		<ng-container
			*ngIf="template"
			appDynamicComponent
			[appDynamicComponentSelector]="template.Selector"
			[appDynamicComponentHtml]="template.Html"
			[appDynamicComponentStyle]="template.Style"
			[appDynamicComponentContext]="{ data: data }"
		></ng-container>`,
})
export class PopupComponent extends DynamicComponent implements OnInit {

	constructor(store: Store<AppState>, facadeService: FacadeService) {
		super(store, SignInRoutes.NosId.Popup, facadeService);
	}

	async ngOnInit() {
		await super.ngOnInit();
		this.data = {
			PopupPosition,
			PopupButtonType,
			popup$: this.store.select(selectPopup),
			doAction: this.doAction.bind(this),
			dismiss: this.dismiss.bind(this),
		};
	}

	doAction(popupAction: PopupAction): boolean {
		this.store.dispatch(hidePopup());

		if (typeof popupAction.Callback === "function") {
			popupAction.Callback();
			return true;
		}

		return false;
	}

	dismiss(event: MouseEvent, father: HTMLElement): void {
		this.store
			.select(selectPopup)
			.pipe(take(1))
			.subscribe((state: PopupState) => {
				if (state.Dismissable) {
					const target = event.target as HTMLElement;

					const isCloseIcon = target.classList.contains("close");

					const isFather = father === target;

					if (isFather || isCloseIcon) {
						this.store.dispatch(hidePopup());
					}
				}
			});
	}
}
