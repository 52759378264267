import { ExternalLoginProviders } from "#baseUrl/models/configurations/external-login/login-providers.enum";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded } from "#baseUrl/state/current-view/current-view-actions";
import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigurationService } from "../configuration.service";
import { ExternalLoginService } from "./external-login.service";

type AppleLoginClient = any;

@Injectable()
export class AppleLoginService extends ExternalLoginService {
	private readonly oAuthClient: Promise<AppleLoginClient>;

	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		protected readonly zone: NgZone
	) {
		super(httpService, configurationService, store, zone);
	}

	login(): void {
		this.oAuthClient.then((client) => {
			client
				.signIn()
				.then((response) => {
					this.finishAuthentication(ExternalLoginProviders.Apple, response.authorization.id_token);
				})
				.catch((err) => {
					this.store.dispatch(setViewDataLoaded());
				});
		});
	}

	logout(): void {
		this.oAuthClient.then((client) => {});
	}

	initProvider(): Promise<AppleLoginClient> {
		return new Promise((resolve) => {
			//	Async load apple sdk script
			const node = document.createElement("script");
			node.src = this.configurationService.ExternalLoginProviders.Apple.API;
			node.async = true;

			// 	Retrieve the singleton for the FB library on load script
			node.onload = () => {
				window["AppleID"]?.auth.init({
					clientId: this.configurationService.ExternalLoginProviders.Apple.ClientId,
					usePopup: true,
					redirectURI: window.location.origin
				});
				resolve(window["AppleID"]?.auth);
			};

			node.onerror = () => {
				resolve();
			};

			//	Inject script into head element
			document.getElementsByTagName("head")[0].appendChild(node);
		});
	}
}
