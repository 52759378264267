import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { AppState } from "../app-state";
import { hideSnackBar, SHOW_SNACKBAR } from "./snackbar-actions";
import { SnackBarState } from "./snackbar-state";
import { WidgetConfigurationService } from "@nosinovacao/nosid-mfe-common";

@Injectable()
export class SnackBarEffects {
	private dismissTimeout: ReturnType<typeof setTimeout>;
	navigateToError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(SHOW_SNACKBAR),
				tap((state: SnackBarState) => {
					if (this.dismissTimeout) {
						clearTimeout(this.dismissTimeout);
						this.dismissTimeout = null;
					}

					this.dismissTimeout = setTimeout(() => {
						this.dismissTimeout = null;
						this.store.dispatch(hideSnackBar());
					}, this.widgetsConfigurationService.SnackBarTimeout);
				})
			),
		{dispatch: false}
	);

	constructor(
		private readonly actions$: Actions,
		private readonly store: Store<AppState>,
		private readonly widgetsConfigurationService: WidgetConfigurationService
	) {
	}
}
