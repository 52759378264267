export enum Option {
	Email = "Email",
	EmailNotValidated = "EmailNotValidated",
	EmailValidated = "EmailValidated",
	Nif = "Nif",
	NifAndEmail = "NifAndEmail",
	NifAndPhone = "NifAndPhone",
	Passport = "Passport",
	PassportAndEmail = "PassportAndEmail",
	PassportAndPhone = "PassportAndPhone",
	Phone = "Phone",
	PrimaryUser = "PrimaryUser",
	SecondaryUser = "SecondaryUser",
	UsernameAndEmail = "UsernameAndEmail",
	UsernameAndPhone = "UsernameAndPhone",
	Username = "Username"
}
