import { Injectable, Injector } from "@angular/core";
import {
	AccountAssociationService,
	AccountProtectionService,
	AccountSelectionService,
	AuthenticationService,
	GenericService,
	MandatoryEmailService,
	PasswordRecoveryService,
	SignUpService,
	SocialNetworkService,
	TermsAndConsentsService,
	TwoFactorAuthenticationService,
} from "./aa";
import { AAService } from "./aa/aa.service";
import { DeviceCodeService } from "./aa/deviceCode.service";
import { KidsProfileService } from "./aa/kidsProfile.service";
import { ConfigurationService } from "./configuration.service";
import { DeviceIdService } from "./deviceId.service";
import { FacebookLoginService } from "./external-login/facebook-login.service";
import { GoogleLoginService } from "./external-login/google-login.service";
import { LoggerService } from "./logging/logger.service";
import { MageService } from "./mage.service";
import { ChangeAccountService } from "./navigation/changeAccount.service";
import { NavigationStateService } from "./navigation/navigation-state.service";
import { TemplatesConfigurationService } from "./templatesConfiguration.service";
import { UtilsService } from "./utils.service";
import { DynamicFactory, LocalStorageService, PhoneService, SessionStorageService, WidgetConfigurationService } from "@nosinovacao/nosid-mfe-common";
import { EnterpriseContextService } from "#baseUrl/services/enterprise-context.service";
import { AppleLoginService } from "./external-login/apple-login.service";
import { CookiebotService } from "./cookiebot/cookiebot.service";

@Injectable()
export class FacadeService {
	private configurationServiceInstance: ConfigurationService;
	private templatesConfigurationServiceInstance: TemplatesConfigurationService;
	private widgetsConfigurationServiceInstance: WidgetConfigurationService;
	private loggerServiceInstance: LoggerService;
	private utilsServiceInstance: UtilsService;
	private googleLoginServiceInstance: GoogleLoginService;
	private facebookLoginServiceInstance: FacebookLoginService;
	private appleLoginServiceInstance: AppleLoginService;
	private localStorageServiceInstance: LocalStorageService;
	private sessionStorageServiceInstance: SessionStorageService;
	private aAServiceInstance: AAService;
	private authenticationServiceInstance: AuthenticationService;
	private mandatoryEmailServiceInstance: MandatoryEmailService;
	private signUpServiceInstance: SignUpService;
	private passwordRecoveryServiceInstance: PasswordRecoveryService;
	private mageServiceInstance: MageService;
	private changeAccountServiceInstance: ChangeAccountService;
	private accountProtectionServiceInstance: AccountProtectionService;
	private termsAndConsentsServiceInstance: TermsAndConsentsService;
	private accountAssociationServiceInstance: AccountAssociationService;
	private accountSelectionServiceInstance: AccountSelectionService;
	private socialNetworkServiceInstance: SocialNetworkService;
	private twoFactorAuthenticationServiceInstance: TwoFactorAuthenticationService;
	private genericServiceInstance: GenericService;
	private navigationStateServiceInstance: NavigationStateService;
	private DynamicFactoryInstance: DynamicFactory;
	private phoneServiceInstance: PhoneService;
	private kidsProfileServiceInstance: KidsProfileService;
	private deviceCodeServiceInstance: DeviceCodeService;
	private deviceIdServiceInstance: DeviceIdService;
	private enterpriseContextInstance: EnterpriseContextService;
	private cookiebotServiceInstance: CookiebotService;

	constructor(private readonly injector: Injector) { }

	public get configurationService(): ConfigurationService {
		if (!this.configurationServiceInstance) {
			this.configurationServiceInstance = this.injector.get(ConfigurationService);
		}

		return this.configurationServiceInstance;
	}

	public get templatesConfigurationService(): TemplatesConfigurationService {
		if (!this.templatesConfigurationServiceInstance) {
			this.templatesConfigurationServiceInstance = this.injector.get(TemplatesConfigurationService);
		}

		return this.templatesConfigurationServiceInstance;
	}

	public get widgetsConfigurationService(): WidgetConfigurationService {
		if (!this.widgetsConfigurationServiceInstance) {
			this.widgetsConfigurationServiceInstance = this.injector.get(WidgetConfigurationService);
		}

		return this.widgetsConfigurationServiceInstance;
	}

	public get loggerService(): LoggerService {
		if (!this.loggerServiceInstance) {
			this.loggerServiceInstance = this.injector.get(LoggerService);
		}

		return this.loggerServiceInstance;
	}

	public get utilsService(): UtilsService {
		if (!this.utilsServiceInstance) {
			this.utilsServiceInstance = this.injector.get(UtilsService);
		}

		return this.utilsServiceInstance;
	}

	public get googleLoginService(): GoogleLoginService {
		if (!this.googleLoginServiceInstance) {
			this.googleLoginServiceInstance = this.injector.get(GoogleLoginService);
		}

		return this.googleLoginServiceInstance;
	}

	public get facebookLoginService(): FacebookLoginService {
		if (!this.facebookLoginServiceInstance) {
			this.facebookLoginServiceInstance = this.injector.get(FacebookLoginService);
		}

		return this.facebookLoginServiceInstance;
	}
	public get appleLoginService(): AppleLoginService {
		if (!this.appleLoginServiceInstance) {
			this.appleLoginServiceInstance = this.injector.get(AppleLoginService);
		}

		return this.appleLoginServiceInstance;
	}

	public get localStorageService(): LocalStorageService {
		if (!this.localStorageServiceInstance) {
			this.localStorageServiceInstance = this.injector.get(LocalStorageService);
		}

		return this.localStorageServiceInstance;
	}

	public get sessionStorageService(): SessionStorageService {
		if (!this.sessionStorageServiceInstance) {
			this.sessionStorageServiceInstance = this.injector.get(SessionStorageService);
		}

		return this.sessionStorageServiceInstance;
	}

	public get aAService(): AAService {
		if (!this.aAServiceInstance) {
			this.aAServiceInstance = this.injector.get(AAService);
		}

		return this.aAServiceInstance;
	}

	public get authenticationService(): AuthenticationService {
		if (!this.authenticationServiceInstance) {
			this.authenticationServiceInstance = this.injector.get(AuthenticationService);
		}

		return this.authenticationServiceInstance;
	}

	public get mandatoryEmailService(): MandatoryEmailService {
		if (!this.mandatoryEmailServiceInstance) {
			this.mandatoryEmailServiceInstance = this.injector.get(MandatoryEmailService);
		}

		return this.mandatoryEmailServiceInstance;
	}

	public get signUpService(): SignUpService {
		if (!this.signUpServiceInstance) {
			this.signUpServiceInstance = this.injector.get(SignUpService);
		}

		return this.signUpServiceInstance;
	}

	public get passwordRecoveryService(): PasswordRecoveryService {
		if (!this.passwordRecoveryServiceInstance) {
			this.passwordRecoveryServiceInstance = this.injector.get(PasswordRecoveryService);
		}

		return this.passwordRecoveryServiceInstance;
	}

	public get mageService(): MageService {
		if (!this.mageServiceInstance) {
			this.mageServiceInstance = this.injector.get(MageService);
		}

		return this.mageServiceInstance;
	}

	public get changeAccountService(): ChangeAccountService {
		if (!this.changeAccountServiceInstance) {
			this.changeAccountServiceInstance = this.injector.get(ChangeAccountService);
		}

		return this.changeAccountServiceInstance;
	}

	public get accountProtectionService(): AccountProtectionService {
		if (!this.accountProtectionServiceInstance) {
			this.accountProtectionServiceInstance = this.injector.get(AccountProtectionService);
		}

		return this.accountProtectionServiceInstance;
	}

	public get termsAndConsentsService(): TermsAndConsentsService {
		if (!this.termsAndConsentsServiceInstance) {
			this.termsAndConsentsServiceInstance = this.injector.get(TermsAndConsentsService);
		}

		return this.termsAndConsentsServiceInstance;
	}

	public get accountAssociationService(): AccountAssociationService {
		if (!this.accountAssociationServiceInstance) {
			this.accountAssociationServiceInstance = this.injector.get(AccountAssociationService);
		}

		return this.accountAssociationServiceInstance;
	}

	public get accountSelectionService(): AccountSelectionService {
		if (!this.accountSelectionServiceInstance) {
			this.accountSelectionServiceInstance = this.injector.get(AccountSelectionService);
		}

		return this.accountSelectionServiceInstance;
	}

	public get socialNetworkService(): SocialNetworkService {
		if (!this.socialNetworkServiceInstance) {
			this.socialNetworkServiceInstance = this.injector.get(SocialNetworkService);
		}

		return this.socialNetworkServiceInstance;
	}

	public get twoFactorAuthenticationService(): TwoFactorAuthenticationService {
		if (!this.twoFactorAuthenticationServiceInstance) {
			this.twoFactorAuthenticationServiceInstance = this.injector.get(TwoFactorAuthenticationService);
		}

		return this.twoFactorAuthenticationServiceInstance;
	}

	public get genericService(): GenericService {
		if (!this.genericServiceInstance) {
			this.genericServiceInstance = this.injector.get(GenericService);
		}

		return this.genericServiceInstance;
	}

	public get navigationStateService(): NavigationStateService {
		if (!this.navigationStateServiceInstance) {
			this.navigationStateServiceInstance = this.injector.get(NavigationStateService);
		}

		return this.navigationStateServiceInstance;
	}

	public get DynamicFactory(): DynamicFactory {
		if (!this.DynamicFactoryInstance) {
			this.DynamicFactoryInstance = this.injector.get(DynamicFactory);
		}

		return this.DynamicFactoryInstance;
	}

	public get phoneService(): PhoneService {
		if (!this.phoneServiceInstance) {
			this.phoneServiceInstance = this.injector.get(PhoneService);
		}

		return this.phoneServiceInstance;
	}

	public get kidsProfileService(): KidsProfileService {
		if (!this.kidsProfileServiceInstance) {
			this.kidsProfileServiceInstance = this.injector.get(KidsProfileService);
		}

		return this.kidsProfileServiceInstance;
	}

	public get deviceCodeService(): DeviceCodeService {
		if (!this.deviceCodeServiceInstance) {
			this.deviceCodeServiceInstance = this.injector.get(DeviceCodeService);
		}

		return this.deviceCodeServiceInstance;
	}

	public get deviceIdService(): DeviceIdService {
		if (!this.deviceIdServiceInstance) {
			this.deviceIdServiceInstance = this.injector.get(DeviceIdService);
		}

		return this.deviceIdServiceInstance;
	}

	public get enterpriseContextService(): EnterpriseContextService {
		if (!this.enterpriseContextInstance) {
			this.enterpriseContextInstance = this.injector.get(EnterpriseContextService);
		}
		return this.enterpriseContextInstance;
	}

	public get cookiebotService(): CookiebotService {
		if (!this.cookiebotServiceInstance) {
			this.cookiebotServiceInstance = this.injector.get(CookiebotService);
		}
		return this.cookiebotServiceInstance;
	}
}
