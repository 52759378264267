import { Injectable } from "@angular/core";
import { BaseCookiebotService } from "@nosinovacao/nosid-mfe-common";
import { ConfigurationService } from "../configuration.service";

@Injectable()
export class CookiebotService extends BaseCookiebotService {
	constructor(configurationService: ConfigurationService) {
		super(
			configurationService.CookiebotConfig.id,
			configurationService.CookiebotConfig.src,
			configurationService.CookiebotConfig.cbId,
		);
	}
}
