import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { ResponseAction } from "../../models/dto/response-action.model";
import { NavigationStateService } from "../../services/navigation/navigation-state.service";
import { UPDATE_TOKEN } from "../current-view/current-view-actions";

@Injectable()
export class CurrentViewEffects {
	updateToken$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(UPDATE_TOKEN),
				tap((response: ResponseAction<any>) => {
					this.navigationStateService.updateCurrentStateToken(response.Token);
				})
			),
		{dispatch: false}
	);

	constructor(private readonly actions$: Actions, private readonly navigationStateService: NavigationStateService) {
	}
}
