import { Component } from "@angular/core";

@Component({
	selector: "auth-guard-component",
	template: "<div>Should not be rendered</div>",
})
export class GuardComponent {
	/*
		THIS COMPONENT IS USED JUST TO TRIGGER THE AUTHGUARD SERVICE FOR THE /AUTHORIZE REQUEST REDIRECTIONS
	*/
}
