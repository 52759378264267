import { Injectable } from "@angular/core";
import { Option } from "#baseUrl/models/analytics/option.enum";
import { PhoneService } from "@nosinovacao/nosid-mfe-common";
import { FormControl, Validators } from "@angular/forms";
import { ConfigurationService } from "./configuration.service";
import { emailValidator } from "#baseUrl/shared/validators/email.validator";

@Injectable()
export class UtilsService {
	constructor(private readonly config: ConfigurationService) {}
	redirect(url) {
		window.location.href = this.sanitizeUrl(url, this.config.NosIdPortalLink);
	}

	isNumeric(val: string): boolean {
		return !window.isNaN(+val);
	}

	getLogOption(phoneService: PhoneService, alias: string, aliasType: string) {
		if (aliasType === Option.Phone) {
			const countryCode = phoneService.parseCountryCode(alias);

			return `${Option.Phone}@${countryCode.Code}`;
		}

		return aliasType;
	}

	getAliasType(alias: string) {
		const control = new FormControl(alias, emailValidator);

		if (control.errors === null) {
			return Option.Email;
		} else if (!isNaN(+alias)) {
			return Option.Phone;
		} else {
			return Option.Username;
		}
	}

	openInOtherTab(url) {
		window.open(url);
	}

	sanitizeUrl(url: string, def: string): string {
		if (!url?.trim?.()) {
			return def;
		}
		if (this.isBase64(url)) {
			url = atob(url);
		}
		if (url.includes("javascript:") || url.includes("data:text/html")) {
			return def;
		}

		return url;
	}

	private isBase64(str: string): boolean {
		if (!str?.trim?.()) {
			return false;
		}
		try {
			const reg = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
			if (reg.test(str)) {
				return true;
			}
			const reEncodedValue = btoa(atob(str));
			if (
				reEncodedValue
					.replace(str, "")
					.split("")
					.every((char) => char === "=")
			) {
				return true;
			}
			return false;
		} catch {
			return false;
		}
	}
}
