import { AuthorizeParams } from "#baseUrl/models/configurations/auth/autorizeParams.interface";
import { LogoutParams } from "#baseUrl/models/configurations/auth/logoutParams.interface";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, concatMap, mergeMap, tap } from "rxjs/operators";
import { PasswordRequest } from "../../models/dto/outbound/password-request.model";
import { AppState } from "../../state/app-state";
import { navigateTo, navigateToError } from "../../state/navigation/navigation-actions";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { UtilsService } from "../utils.service";
import { OneStepLogin } from "#baseUrl/models/dto/outbound/oneStepLogin.model";
import { EnterpriseContextService } from "#baseUrl/services/enterprise-context.service";
import { LocalStorageService, SessionStorageService } from "@nosinovacao/nosid-mfe-common";
import { setViewDataLoaded, setViewDataLoading } from "#baseUrl/state/current-view/current-view-actions";
import { AccountCredentials } from "#baseUrl/models/dto/inbound/success/accountCredentials.model";
import { Action } from "#baseUrl/models/navigation/action.enum";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class AuthenticationService extends AAService {
	constructor(
		private readonly enterpriseContextService: EnterpriseContextService,
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		private readonly localStorage: LocalStorageService,
		private readonly sessionStorage: SessionStorageService,
		private readonly utilsService: UtilsService,
		recaptchaService: ReCaptchaV3Service
	) {
		super(httpService, configurationService, store, recaptchaService);
	}

	authorize(params: Params): void {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.Authorize}`;

		//	ANGULAR POST WITH SPECIAL CHARACTERS HAVE A BUG. WITH EMAILS LIKE "my+email@gmail.com" WILL CAUSE A USER NOT FOUND.
		//	TO FIX THAT WE DECODE ALL AUTHORIZE ARGUMENTS
		const q: { [key: string]: any } = {};
		Object.keys(params).forEach((k) => (q[k] = decodeURIComponent(params[k] || "")));

		const body = {
			client_id: q["client_id"],
			response_type: q["response_type"],
			scope: q["scope"],
			redirect_uri: this.utilsService.sanitizeUrl(q["redirect_uri"], this.configurationService.NosIdPortalLink),
			state: q["state"],
			nonce: q["nonce"],
			auto_login: q["auto_login"],
			prompt: q["prompt"],
			login_hint:
				q["login_hint"] ||
				this.sessionStorage.getString(`${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.LoginHint}`),
			code_challenge: q["code_challenge"],
			code_challenge_method: q["code_challenge_method"],
			apigee_org: q["apigee_org"],
			apigee_env: q["apigee_env"],
			apigee_host: q["apigee_host"],
			proxy_basepath: q["proxy_basepath"],
			proxy_version: q["proxy_version"],
			sign_up: q["sign_up"] === "true",
			single_page_login: this.isSinglePageLogin(q["proxy_version"]),
		} as AuthorizeParams;

		this.enterpriseContextService.enterpriseContext = q["enterprise_context"] ?? "";

		this.httpService.post(url, body).subscribe((res: ResponseAction<any>) => {
			if (!!res.Error) {
				this.store.dispatch(navigateToError(res));
			} else {
				this.store.dispatch(navigateTo(res));
			}
		});
	}

	isSinglePageLogin(proxyVersion = ""): boolean {
		return this.configurationService.SingleStepLoginValidProxyVersions.some((version) => version === proxyVersion);
	}

	validatePassword(password: string, username?: string, honeypot?: string, tokenChallenge?: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.AuthorizePassword}`;

		const body: PasswordRequest = {
			Password: password,
			Username: honeypot ? honeypot : undefined,
		};

		if (tokenChallenge) {
			return this.doValidatePasswordWithCredentials(url, body, username, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("password").pipe(
			mergeMap((token) => {
				return this.doValidatePasswordWithCredentials(url, body, username, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	private doValidatePasswordWithCredentials(url: string, body: PasswordRequest, credential: string, headers?: { [key: string]: string }) {
		this.store.dispatch(setViewDataLoading());
		return this.httpService.post(url, body, { headers }).pipe(
			tap((res: ResponseAction<any>) => {
				const passwordRes = res as ResponseAction<AccountCredentials>;
				if (res.Action === Action.UserBlocked) {
					passwordRes.Data = { ...(passwordRes.Data || {}), Username: credential || "" };
				}
				this.handleResponse(passwordRes);
			})
		);
	}

	loginOneStep(username: string, password: string, honeyPot?: string, tokenChallenge?: string): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.OneStepLogin}`;

		const body: OneStepLogin = { Password: password, Username: username, Email: honeyPot ? honeyPot : undefined };

		if (tokenChallenge) {
			return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: tokenChallenge, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}

		return this.recaptchaService.execute("loginOneStep").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	logout(redirectUri: string): Observable<any> {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.Logout}`;

		const sessionTokenKey = `${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.SessionToken}`;

		const deviceIdKey = `${this.configurationService.LocalStorage.BaseToken}${this.configurationService.LocalStorage.DeviceIdKey}`;
		const deviceId = this.localStorage.getString(deviceIdKey);

		const body = {
			SessionToken: this.localStorage.getString(sessionTokenKey) || this.sessionStorage.getString(sessionTokenKey),
		} as LogoutParams;

		//	CLEAN ALL STORAGES
		this.localStorage.clear();
		this.sessionStorage.clear();

		// ADD DEVICE ID AGAIN
		if (deviceId) {
			this.localStorage.setString(deviceIdKey, deviceId);
		}

		return this.httpService.post(url, body).pipe(
			catchError(() => {
				this.utilsService.redirect(redirectUri);
				return of(false);
			}),
			concatMap(() => {
				this.utilsService.redirect(redirectUri);
				return of(false);
			})
		);
	}
}
