import { createReducer, on } from "@ngrx/store";
import { NavigationDirectionEnum } from "#baseUrl/models/navigation/navigation-direction.enum";
import { navigateBackward, navigateForward, navigateRefresh } from "./navigation-actions";
import { NavigationState } from "./navigation-state";

export const initialState = {
	Direction: NavigationDirectionEnum.Refresh,
} as NavigationState;

const reducer = createReducer(
	initialState,
	on(navigateBackward, () => {
		return {
			Direction: NavigationDirectionEnum.Backward,
		} as NavigationState;
	}),
	on(navigateRefresh, () => {
		return {
			Direction: NavigationDirectionEnum.Refresh,
		} as NavigationState;
	}),
	on(navigateForward, () => {
		return {
			Direction: NavigationDirectionEnum.Forward,
		} as NavigationState;
	})
);

export function navigationReducer(state, action) {
	return reducer(state, action);
}
