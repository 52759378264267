import { Injectable } from "@angular/core";
import { ConsoleProvider } from "./console-logger.service";
import { FirebaseProvider } from "./firebase-logger.service";
import { LoggerProvider } from "./logger.interface";
import { ConfigurationService } from "#baseUrl/services/configuration.service";
import { TemplatesConfigurationService } from "#baseUrl/services/templatesConfiguration.service";

@Injectable()
export class LoggerService {
	providers: LoggerProvider[];

	constructor(private readonly configurationService: ConfigurationService, private readonly templatesConfiguration: TemplatesConfigurationService) {
		this.initializeProviders();
	}

	logEvent(message: string, data?: { [key: string]: any }): void {
		this.providers.forEach((p) => p.logEvent(message, data));
	}

	private initializeProviders(): void {
		this.providers = [];

		if (this.configurationService.LoggingConfiguration.Console) {
			this.providers.push(new ConsoleProvider(this.configurationService, this.templatesConfiguration));
		}

		if (this.configurationService.LoggingConfiguration.Firebase) {
			this.providers.push(new FirebaseProvider(this.configurationService, this.templatesConfiguration));
		}
	}
}
