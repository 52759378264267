import { ChangeMandatoryEmail } from "#baseUrl/models/dto/outbound/changeMandatoryEmail.model";
import { MandatoryEmailCode } from "#baseUrl/models/dto/outbound/mandatoryEmailCode.model";
import { AppState } from "#baseUrl/state/app-state";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { mergeMap } from "rxjs/operators";

@Injectable()
export class MandatoryEmailService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	validateCode(code: string, tokenChallenge: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.MandatoryEmailEmailCodeValidate}`;
		const body: MandatoryEmailCode = { Value: code };

		if (tokenChallenge) {
			return this.doPost(url, body, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("MandatoryEmailValidateCode").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	notMyEmail(tokenChallenge: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.MandatoryEmailNotMyEmail}`;

		if (tokenChallenge) {
			return this.doGet(url, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("MandatoryEmailNotMyEmail").pipe(
			mergeMap((token) => {
				return this.doGet(url, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	updateEmail(email: string, tokenChallenge: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.MandatoryEmailUpdateEmail}`;

		const body: ChangeMandatoryEmail = { Value: email };

		if (tokenChallenge) {
			return this.doPost(url, body, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("MandatoryEmailUpdateEmail").pipe(
			mergeMap((token) => {
				return this.doPost(url, body, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}
}
