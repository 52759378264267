import { BaseLoggerConfiguration } from "./base-logger-configuration.interface";

// TODO DELETE
export interface FirebaseConfiguration extends BaseLoggerConfiguration {
	AppName: string;
	ApiKey: string;
	AuthDomain: string;
	ProjectId: string;
	StorageBucket: string;
	MessagingSenderId: string;
	AppId: string;
	MeasurementId: string;
}

export enum FirebaseNativeLogginEnum {
	Android = "Android",
	IOS = "IOS",
}
