import { createReducer, on } from "@ngrx/store";
import { SnackBarState, SnackBarType } from "./snackbar-state";
import { hideSnackBar, showSnackBar } from "./snackbar-actions";

export const initialState = {
	Visible: false,
	Type: SnackBarType.Info,
	Title: "",
	Message: "",
} as SnackBarState;

const reducer = createReducer(
	initialState,

	on(hideSnackBar, (currentState: SnackBarState) => {
		return {
			...initialState,
		};
	}),

	on(showSnackBar, (currentState: SnackBarState, payload: SnackBarState) => {
		return {
			...initialState,
			...payload,
			Visible: true,
		};
	})
);

export function snackBarReducer(state, action) {
	return reducer(state, action);
}
