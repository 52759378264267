import { AssociationType } from "#baseUrl/models/dto/outbound/associationType.enum";
import { AccountCredentials } from "#baseUrl/models/dto/inbound/success/accountCredentials.model";
import { ContactIdentification } from "#baseUrl/models/dto/outbound/household-identification.model";
import { ValidateCode } from "#baseUrl/models/dto/outbound/validateCode.model";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { setViewDataLoaded, setViewDataLoading } from "#baseUrl/state/current-view/current-view-actions";
import { navigateTo, navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { mergeMap, tap } from "rxjs/operators";
import { PasswordRequest } from "../../models/dto/outbound/password-request.model";
import { AppState } from "../../state/app-state";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class PasswordRecoveryService extends AAService {
	constructor(
		protected readonly httpService: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		captcha: ReCaptchaV3Service
	) {
		super(httpService, configurationService, store, captcha);
	}

	initPasswordRecovery(username?: string, tokenChallenge?: string, enable2FA?: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.InitPasswordRecovery}${
			typeof enable2FA === "boolean" ? `?EnableTwoFactor=${enable2FA}` : ""
		}`;

		if (tokenChallenge) {
			return this.doPasswordRequestWithCredentials(url, username, {
				[this.CAPTCHA_HTTP_HEADER]: tokenChallenge,
				[this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true",
			});
		}

		return this.recaptchaService.execute("initPasswordRecovery").pipe(
			mergeMap((token) => {
				return this.doPasswordRequestWithCredentials(url, username, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	passwordRecoveryCodeValidation(code: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PasswordRecoveryCodeValidation}`;

		const body: ValidateCode = { Value: code };

		return this.doPost(url, body);
	}

	passwordRecoveryAllowSms(tokenChallenge?: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PasswordRecoveryAllowSMS}`;
		if (tokenChallenge) {
			return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: tokenChallenge, [this.CAPTCHA_CHALLENGE_HTTP_HEADER]: "true" });
		}
		return this.recaptchaService.execute("passwordRecoverySendSMS").pipe(
			mergeMap((token) => {
				return this.doPost(url, {}, { [this.CAPTCHA_HTTP_HEADER]: token });
			})
		);
	}

	passwordRecoveryContactSelection(type: AssociationType) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PasswordRecoveryContactSelection}`;
		const body: ContactIdentification = { Type: type };

		return this.doPost(url, body);
	}

	passwordRecoveryNewPassword(password: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PasswordRecoveryNewPassword}`;

		const body: PasswordRequest = { Password: password };

		return this.doPost(url, body);
	}

	passwordRecoveryFromEmail(credential: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PasswordRecoveryFromEmail}`;
		return this.doPasswordRequestWithCredentials(url, credential);
	}

	private doPasswordRequestWithCredentials(url: string, credential: string, headers?: any) {
		this.store.dispatch(setViewDataLoading());

		return this.httpService.get(url, { headers }).pipe(
			tap((res: ResponseAction<any>) => {
				this.store.dispatch(setViewDataLoaded());

				if (!!res.Error) {
					this.store.dispatch(navigateToError(res));
				} else {
					const passwordRes = res as ResponseAction<AccountCredentials>;
					passwordRes.Data = { ...(passwordRes.Data || {}), Username: credential || "" };
					this.store.dispatch(navigateTo(passwordRes));
				}
			})
		);
	}
}
