import { EventParameters } from "#baseUrl/models/analytics/event-parameters";
import { createAction, props } from "@ngrx/store";
import { LoggerState } from "./logger-state";

export const SET_CURRENT_PAGE = "[LOGGER] Set current page";

export const GET_CURRENT_PAGE = "[LOGGER] Get current page";

export const GET_CURRENT_PAGE_COMPLETED = "[LOGGER] Get current page completed";

export const SEND_LEAVE_VIEW_EVENT = "[LOGGER] Send leave view event";

export const SEND_SCREEN_ACTION_EVENT = "[LOGGER] Send screen action event";

export const SEND_SCREEN_NAVIGATION_EVENT = "[LOGGER] Send screen navigation event";

export const SEND_EXCEPTION_EVENT = "[LOGGER] Send exception event";

export const setCurrentPage = createAction(SET_CURRENT_PAGE, props<{ routerKey: string }>());

export const setCurrentPageCompleted = createAction(GET_CURRENT_PAGE_COMPLETED, props<{ loggerState: LoggerState }>());

export const getCurrentPage = createAction(GET_CURRENT_PAGE);

export const sendLeaveViewEvent = createAction(SEND_LEAVE_VIEW_EVENT);

export const sendScreenActionEvent = createAction(SEND_SCREEN_ACTION_EVENT, props<{ eventParameters: EventParameters }>());

export const sendScreenNavigationEvent = createAction(SEND_SCREEN_NAVIGATION_EVENT, props<{ eventParameters: EventParameters }>());

export const sendExceptionEvent = createAction(SEND_EXCEPTION_EVENT, props<{ eventParameters: EventParameters }>());
