import firebase from "firebase/app";

export type AnalyticsWebInterface = {
	logEvent(name: string, params?: string): void;
	setUserProperty(name: string, value: string): void;
};

export type WebkitMessageHandlers = {
	postMessage(message: WebkitMessage): void;
};

export interface WebkitMessage {
	command: "setUserProperty" | "logEvent";
	name: string;
	value?: any;
	parameters?: any;
}

export abstract class NativeAnalytics<T = AnalyticsWebInterface | WebkitMessageHandlers> {
	abstract get analyticsInterface(): T;

	logEvent<T extends string>(
		eventName: firebase.analytics.CustomEventName<T>,
		eventParams?: { [key: string]: any },
		options?: firebase.analytics.AnalyticsCallOptions
	) {
		if (!eventName) {
			return;
		}
		this.logEventInternal(eventName, eventParams);
	}

	setUserProperties(properties: firebase.analytics.CustomParams, options?: firebase.analytics.AnalyticsCallOptions) {
		Object.entries(properties || {}).forEach(([name, value]) => {
			if (!name || !value) {
				return;
			}
			this.setSingleUserProperty(name, value);
		});
	}

	protected abstract setSingleUserProperty(name, value);

	protected abstract logEventInternal(name: string, value?: { [key: string]: any });
}
