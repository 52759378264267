import { Injectable } from "@angular/core";
import { LocalStorageService } from "@nosinovacao/nosid-mfe-common";
import { ConfigurationService } from "./configuration.service";

@Injectable()
export class DeviceIdService {
	private id: Promise<string>;

	constructor(private readonly storage: LocalStorageService, private readonly config: ConfigurationService) {
		this.id = this.getFingerPrintId()
			.then((id) => id ?? this.getFallbackId())
			.catch(() => this.getFallbackId());
	}

	get deviceId(): Promise<string> {
		return this.id;
	}

	private getFallbackId(): string {
		return this.getFromStorage() ?? this.createIdAndSave();
	}

	private get storageKey(): string {
		return `${this.config.LocalStorage.BaseToken}${this.config.LocalStorage.DeviceIdKey}`;
	}

	private getFromStorage(): string {
		return this.storage.getString(this.storageKey);
	}

	private createIdAndSave(): string {
		if (!window.crypto || !window.crypto.getRandomValues) {
			return undefined;
		}
		const id: string = [...window.crypto.getRandomValues(new Uint32Array(4))].map((v) => v.toString(16)).join("-");
		this.storage.setString(this.storageKey, id);
		return id;
	}

	private getDeviceSize(): string {
		const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		return (width * height).toString();
	}

	private async getFingerPrintId(): Promise<string> {
		if (!window.crypto || !window.crypto.subtle || !window.crypto.subtle.digest) {
			return undefined;
		}
		const canvas = document.getElementById("device-id")! as HTMLCanvasElement;
		const ctx = canvas.getContext("2d");

		ctx.fillStyle = "rgb(255,0,255)";
		ctx.beginPath();
		ctx.rect(20, 20, 150, 100);
		ctx.fill();
		ctx.stroke();
		ctx.closePath();
		ctx.beginPath();
		ctx.fillStyle = "rgb(0,255,255)";
		ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
		ctx.fill();
		ctx.stroke();
		ctx.closePath();

		const txt = "abz190#$%^@£éú";
		ctx.textBaseline = "top";
		ctx.font = '17px "Arial 17"';
		ctx.textBaseline = "alphabetic";
		ctx.fillStyle = "rgb(255,5,5)";
		ctx.rotate(0.03);
		ctx.fillText(txt, 4, 17);
		ctx.fillStyle = "rgb(155,255,5)";
		ctx.shadowBlur = 8;
		ctx.shadowColor = "red";
		ctx.fillRect(20, 12, 100, 5);

		// hashing function
		const src = canvas.toDataURL();
		let hash = 0;

		for (let i = 0; i < src.length; i++) {
			let char = src.charCodeAt(i);
			hash = (hash << 5) - hash + char;
			hash = hash & hash;
		}
		return hash.toString();
	}
}
