import { AssociationType } from "#baseUrl/models/dto/outbound/associationType.enum";
import { ContactInput } from "#baseUrl/models/dto/outbound/contactInput.model";
import { ContactIdentification } from "#baseUrl/models/dto/outbound/household-identification.model";
import { IdentifyHouseHoldConfirmation } from "#baseUrl/models/dto/outbound/identifyHouseHoldConfirmation.model";
import { ValidateCode } from "#baseUrl/models/dto/outbound/validateCode.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { AppState } from "../../state/app-state";
import { ConfigurationService } from "../configuration.service";
import { AAService } from "./aa.service";

@Injectable()
export class AccountAssociationService extends AAService {
	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	identifyHouseHold(type: AssociationType) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationType}`;

		const body: ContactIdentification = { Type: type };

		return this.doPost(url, body);
	}

	identifyHouseHoldCredential(value: string) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationCredential}`;

		const body: ContactInput = { Value: value };

		return this.doPost(url, body);
	}

	secondStepSelection(value: number) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationSecondStepSelection}?id=${value}`;
		return this.doPost(url, {});
	}

	identifyHouseHoldValidateCode(code: string, secondStep: boolean) {
		let url: string;
		if (secondStep) {
			url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationSecondStepValidateCode}`;
		} else {
			url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationVerifyCode}`;
		}

		const body: ValidateCode = { Value: code };

		return this.doPost(url, body);
	}

	identifyHouseHoldConfirmation(accept: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationConfirmation}`;

		const body: IdentifyHouseHoldConfirmation = { Value: accept };

		return this.doPost(url, body);
	}

	serviceAssociationAllowSMS() {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.ServiceAssociationSendSMSCode}`;

		return this.doPost(url, {});
	}
}
