import { AppModule } from "#baseUrl/app.module";
import { EnvironmentConfigurationEnum } from "#baseUrl/models/configurations/environment/environmentConfiguration.enum";
import { SignInConfiguration } from "#baseUrl/models/configurations/environment/signInConfiguration.interface";
import { ClientTemplate } from "#baseUrl/models/configurations/templates/clientTemplate.interface";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { WidgetConfiguration } from "@nosinovacao/nosid-mfe-common";

function getConfigFile<T>(path: string): Promise<T> {
	return fetch(path).then(async (response: Response) => {
		if (response.status === 404) {
			throw new Error(`Could not find config file in path ${path}`);
		}

		return (await response.json()) as T;
	});
}

Promise.all([getConfigFile<SignInConfiguration>("./assets/configs/env.json"), getConfigFile<WidgetConfiguration>("./assets/configs/widgets.json")])
	.then(([env, widgets]) => {
		return Promise.all(env.Templates.map((path) => getConfigFile<ClientTemplate | ClientTemplate[]>(path).catch((_) => null)))
			.then((res) => res.filter((t) => !!t))
			.then((results) =>
				results.reduce<ClientTemplate[]>((acc, curr) => {
					return acc.concat(curr);
				}, [])
			)
			.then((templates) => {
				return {env, templates, widgets};
			});
	})
	.then(({env, templates, widgets}) => {
		if (env.Environment.toLowerCase() === EnvironmentConfigurationEnum.STAGING || env.Environment.toLowerCase() === EnvironmentConfigurationEnum.STABLE) {
			enableProdMode();
		}

		platformBrowserDynamic([
			{
				provide: "config",
				useValue: env,
			},
			{
				provide: "templates",
				useValue: templates,
			},
			{
				provide: "widgets",
				useValue: widgets,
			},
		]).bootstrapModule(AppModule);
	});
