import { createFeatureSelector, createSelector } from "@ngrx/store";

export enum PopupPosition {
	Top,
	Center,
	Bottom,
}

export enum PopupButtonType {
	Primary,
	Secondary,
}

export interface PopupAction {
	Label: string;
	Type: PopupButtonType;
	Callback?: () => void;
}

export interface PopupState {
	Visible?: boolean;
	Dismissable?: boolean;
	Position: PopupPosition;
	Title: string;
	Message: string;
	Actions: PopupAction[];
}

export const popupFeatureSelector = createFeatureSelector("Popup");
export const selectPopup = createSelector(popupFeatureSelector, (state: PopupState) => state);
