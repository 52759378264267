import { ModuleName } from "#baseUrl/models/configurations/routing/module-name.enum";
import { Screen } from "#baseUrl/models/configurations/routing/screen-name.enum";
import { PrivacyPolicy } from "#baseUrl/models/dto/inbound/success/privacyPolicies.model";
import { AcceptConsents } from "#baseUrl/models/dto/outbound/acceptConsents.model";
import { AcceptTermsAndconditions } from "#baseUrl/models/dto/outbound/acceptTermsConditions.model";
import { AcceptPrivacyPolicy } from "#baseUrl/models/dto/outbound/setPrivacyPolicies.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../state/app-state";
import { AAService } from "./aa.service";
import { ConfigurationService } from "../configuration.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Injectable()
export class TermsAndConsentsService extends AAService {
	errorTranslationKey = `${ModuleName.TermsConsents}.${Screen.Consents}.Errors`;

	constructor(httpService: HttpClient, configurationService: ConfigurationService, store: Store<AppState>, captcha: ReCaptchaV3Service) {
		super(httpService, configurationService, store, captcha);
	}

	acceptTermsAndConditions(accepted: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.TermsAndConditions}`;

		const body: AcceptTermsAndconditions = { Accepted: accepted };

		return this.doPost(url, body);
	}

	acceptConsents(accepted: boolean) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.Consents}`;

		const body: AcceptConsents = { Accepted: accepted };

		return this.doPost(url, body);
	}

	acceptPrivacyPolicies(policies: PrivacyPolicy[], values: { [key: string]: boolean }) {
		const url = `${this.microServiceConfig.BaseUrl}${this.microServiceConfig.PrivacyPolicies}`;
		const body: AcceptPrivacyPolicy[] = policies.map(
			(curr) =>
				({
					Id: curr.Id,
					IsAccepted: values[curr.Id],
					TextCategory: curr.TextCategory,
					Type: curr.Type,
				} as AcceptPrivacyPolicy)
		);

		return this.doPost(url, body);
	}
}
