import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "../aa";
import { ConfigurationService } from "../configuration.service";

@Injectable()
export class DirectSignupAuthorizeResolverGuard implements CanActivate {
	constructor(
		private readonly configurationService: ConfigurationService,
		private readonly aaService: AuthenticationService,
		private readonly store: Store
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		const callbackUrl = route.queryParamMap.get("callback_url");
		if (callbackUrl) {
			let url: string;
			try {
				const decodedCallbackUrl = new URL(decodeURIComponent(atob(callbackUrl)));
				decodedCallbackUrl.searchParams.append("sign_up", "true");
				url = decodedCallbackUrl.toString();
			} catch {}
			return this.aaService.logout(url);
		}
		return of(true);
	}
}
