import { createReducer, on } from "@ngrx/store";
import { hidePopup, showPopup } from "./popup-actions";
import { PopupPosition, PopupState } from "./popup-state";

export const initialState = {
	Visible: false,
	Dismissable: true,
	Title: "",
	Message: "",
	Position: PopupPosition.Bottom,
	Actions: [],
} as PopupState;

const _popupReducer = createReducer(
	initialState,

	on(hidePopup, (currentState: PopupState) => {
		return {
			...initialState,
		};
	}),

	on(showPopup, (currentState: PopupState, payload: PopupState) => {
		return {
			...initialState,
			...payload,
			Visible: true,
		};
	})
);

export function popupReducer(state, action) {
	return _popupReducer(state, action);
}
