import { ExternalLoginProviders } from "#baseUrl/models/configurations/external-login/login-providers.enum";
import { ResponseAction } from "#baseUrl/models/dto/response-action.model";
import { AppState } from "#baseUrl/state/app-state";
import { setViewDataLoaded } from "#baseUrl/state/current-view/current-view-actions";
import { navigateTo, navigateToError } from "#baseUrl/state/navigation/navigation-actions";
import { HttpClient } from "@angular/common/http";
import { NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { ConfigurationService } from "../configuration.service";

export abstract class ExternalLoginService {
	constructor(
		protected readonly httpClient: HttpClient,
		protected readonly configurationService: ConfigurationService,
		protected readonly store: Store<AppState>,
		protected readonly zone: NgZone
	) {
		let clientPromise = null;
		Object.defineProperty(this, "oAuthClient", {
			get: () => {
				if (!clientPromise) {
					clientPromise = this.initProvider();
				}

				return clientPromise;
			},
		});
	}

	abstract initProvider(): Promise<any>;

	abstract login(): void;

	abstract logout(): void;

	finishAuthentication(provider: ExternalLoginProviders, token: string): void {
		this.zone.runOutsideAngular(() => {
			const url = `${this.configurationService.APIConfiguration.AA.BaseUrl}${this.configurationService.APIConfiguration.AA.SocialNetworkLogin}`;

			this.httpClient
				.post(
					url,
					{},
					{
						headers: {
							"Social-Network-Authorization": `${provider} ${token}`,
						},
					}
				)
				.subscribe(this.handleResponse.bind(this));
		});
	}

	private handleResponse(res: ResponseAction<any>) {
		this.store.dispatch(setViewDataLoaded());

		if (!!res.Error) {
			this.store.dispatch(navigateToError(res));
		} else {
			this.store.dispatch(navigateTo(res));
		}
	}
}
