import { Action, createReducer, on } from "@ngrx/store";
import { setCurrentPageCompleted } from "./logger-actions";
import { LoggerState } from "./logger-state";

const initialState: LoggerState = {
	Step: null,
	Screen: null
};

export const reducer = createReducer(
	initialState,

	on(setCurrentPageCompleted, (currentState: LoggerState, props: { loggerState: LoggerState }) => {
		return props.loggerState;
	}),
);

export function loggerReducer(state: LoggerState, action: Action): LoggerState {
	return reducer(state, action);
}
