import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ResponseAction } from "../../models/dto/response-action.model";

export interface CurrentViewState {
	Loading: boolean;
	Loaded: boolean;
	HasError: boolean;
	ViewData: ResponseAction<any>;
}

export const currentViewFeatureSelector = createFeatureSelector("CurrentView");

export const selectViewData = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	return state.ViewData;
});

export const selectHasError = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	return state.HasError;
});

export const selectLoaded = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	return state.Loaded;
});

export const selectLoading = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	return state.Loading;
});

export const selectAllErrors = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	const hasParamsError = state.ViewData.Error && state.ViewData.Error.Params && state.ViewData.Error.Params.length > 0;

	if (hasParamsError) {
		return state.HasError && state.ViewData.Error.Params;
	}

	return state.HasError && [state.ViewData.Error];
});

export const selectFirstErrors = createSelector(currentViewFeatureSelector, (state: CurrentViewState) => {
	const hasParamsError = state.ViewData && state.ViewData.Error && state.ViewData.Error.Params && state.ViewData.Error.Params.length > 0;

	if (hasParamsError) {
		return state.HasError && state.ViewData.Error.Params[0];
	}

	return state.HasError && state.ViewData.Error;
});
